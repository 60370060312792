import React, { useState } from 'react'
import { Divider, message, Steps, Card, Avatar, Alert, Typography } from 'antd'
import { useParams } from "react-router-dom"
import { UserOutlined } from '@ant-design/icons'

import axios from 'axios'
import configs from '../../api/configs'
import storageUtils from '../../utils/storageUtils'

import '../css/gameOrder.css'

const { Step } = Steps
const { Meta } = Card
const { Paragraph } = Typography
const DASHEN_TAG_PRICE = 2
const OVERTIME_RATE = 2
const footContent = `最终单价或最终收入可能会由管理员调整，若有任何疑问请联系客服，谢谢！`

export default function GameOrder () {
  const { id } = useParams()
  const [customer_id, setCustomerID] = useState(0)
  const [customer_name, setCustomerName] = useState('')
  const [discount, setDiscount] = useState(1)
  const [game_type, setGameType] = useState('')
  const [game_level, setGameLevel] = useState('')
  const [hours, setHours] = useState(0)
  const [overtime, setOvertime] = useState(0)
  const [status, setStatus] = useState(0)
  const [created_at, setCreatedAt] = useState('')
  const [comments, setComments] = useState('')
  const [amount, setAmount] = useState(0)
  const [price, setPrice] = useState(0)
  const [meta, setMeta] = useState(null)
  const [player_num, setPlayerNum] = useState([])
  const [player_type, setPlayerType] = useState([])
  const [dashen_tag, setDashenTag] = useState(false)

  const leftCardStyle = {
    width: '80%',
    marginLeft: '10%',
    borderRadius: '10px',
    backgroundColor: '#f5f5f5'
  }

  const rightCardStyle = {
    width: '80%',
    marginLeft: '5%',
    borderRadius: '10px',
    backgroundColor: '#f5f5f5'
  }

  const detailCardStyle = {
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#f5f5f5'
  }

  // = class组件中的componentDidMount，第二个参数传一个空数组。
  // 如果第二个参数不是空数组，而是一个状态变量，则该状态变量变化后重新执行useEffect函数
  React.useEffect(() => {
    getOrderDetail()
    getUserInfo()
  }, [])

  // 获取订单详情
  const getOrderDetail = async () => {
    var config_fetch_game_detail = configs.getOrderDetail(id)
    await axios(config_fetch_game_detail)
      .then(
        response => {
          handleDetail(response.data)
        }
      ).catch(
        error => {
          console.log(error)
          message.error('出错了: ' + error)
        }
      )
  }

  const getUserInfo = async () => {
    // 获取订单下成员
    var config_get_user_info = configs.getInfoByOrderId(id)
    await axios(config_get_user_info)
      .then(
        response => {
          setCustomerID(response.data.customers.length !== 0 ? response.data.customers[0].id : 0)
          setCustomerName(response.data.customers.length !== 0 ? response.data.customers[0].name : '无客户信息')
          if (response.data.customers.length !== 0) {
            getCustomerDetail(response.data.customers[0].id)
          }
        }
      ).catch(
        error => {
          console.log(error)
          message.error('出错了: ' + error)
        }
      )

  }

  // 获取用户详情
  const getCustomerDetail = (customer_id) => {
    var config_get_customer_discount = configs.getCustomerDetail(customer_id)
    axios(config_get_customer_discount)
      .then(
        response => {
          setDiscount(response.data.balance.discount)
        }
      ).catch(
        error => {
          console.log(error)
          message.error('出错了: ' + error)
        }
      )
  }

  // 数据处理
  const handleDetail = (dataSource) => {
    setGameType(dataSource.game_type)
    setGameLevel(dataSource.game_level)
    setHours(dataSource.hours)
    setOvertime(dataSource.overtime)
    setStatus(dataSource.status)
    setCreatedAt(dataSource.created_at.split('T')[0] + ' ' + dataSource.created_at.split('T')[1].split('.')[0])
    setComments(dataSource.comments !== 'undefined' ? dataSource.comments : '')
    setMeta(JSON.parse(dataSource.meta))
    setPlayerType(dataSource.meta !== null ? JSON.parse(dataSource.meta).player_type : '无数据')
    setPlayerNum(dataSource.meta !== null ? JSON.parse(dataSource.meta).player_num : '无数据')
    setDashenTag(dataSource.meta !== null ? JSON.parse(dataSource.meta).player_level : '无数据')
    setAmount(dataSource.invoices.length !== 0 ? Number(dataSource.invoices[0].amount) : 0)
    setPrice(dataSource.meta !== null ? (JSON.parse(dataSource.meta).player_level === true ? (JSON.parse(dataSource.meta).price + DASHEN_TAG_PRICE) : JSON.parse(dataSource.meta).price) : 0)
  }

  // 状态进度条
  const StatusView = () => {
    if (status !== 99) {
      return (
        <Steps current={status + 1}>
          <Step title="生成订单" description={created_at} />
          <Step title="审核" description="订单正在等待审核" />
          <Step title="完成" description="订单已完成" />
        </Steps>
      )
    }
    else {
      return (
        <Steps current={3}>
          <Step title="生成订单" description={created_at} />
          <Step title="审核" description="订单正在等待审核" />
          <Step title="完成" description="订单已完成" />
          <Step title="取消" description="订单已取消" />
        </Steps>
      )
    }
  }

  // 订单状态提示框
  const AlertView = () => {
    if (status === 0) {
      return (
        <Alert
          message="待审核"
          description="订单正在等待审核，请耐心等待"
          type="info"
        />
      )
    }
    else if (status === 1) {
      // 陪玩View
      return (
        <Alert
          message="订单已完成"
          description={<p>订单已审核，恭喜您获得了 <span style={{ color: '#ff4d4f', fontSize: 16, fontWeight: 600 }}>${amount}</span> 的收入，已添加至账户余额</p>}
          type="success"
        />
      )
    }
    else if (status === 99) {
      // 陪玩View
      return (
        <Alert
          message="订单已取消"
          description="该订单已撤回，因此您没有获得收入"
          type="error"
        />
      )
    }
  }

  // 下单用户信息
  const CustomerInfo = () => {
    return (
      <Card
        bordered
        style={leftCardStyle}>
        <Meta
          avatar={<Avatar icon={<UserOutlined />} />}
          title="下单用户"
          description={customer_name + " - ID#" + customer_id}
        />
      </Card>
    )
  }

  // 下单陪玩信息
  const PlayerInfo = () => {
    return (
      <Card
        bordered
        style={rightCardStyle}
      >
        <Meta
          avatar={<Avatar icon={<UserOutlined />} />}
          title="接单陪玩"
          description={storageUtils.getUser().name + " - ID#" + storageUtils.getUser().id}
        />
      </Card>
    )
  }

  const DetailInfo = () => {
    return (
      <Card
        bordered
        title={"订单详情" + " - ID#" + id.toString()}
        style={detailCardStyle}
      >
        <ul>
          <Li title="游戏类型" content={game_type} />
          <br />
          <Li title="游戏等级" content={game_level} />
          <br />
          <Li title="陪玩类型" content={player_type.length !== 0 ? player_type[0].split(':$')[0] : '无数据'} />
          <br />
          <Li title="陪玩人数" content={player_num.length !== 0 ? player_num[0].split(':$')[0] : '无数据'} />
          <br />
          <Li title="是否为大神TAG" content={dashen_tag === true ? '是' : '否'} />
          <br />
          <Li title="时长/局数" content={hours} />
          <br />
          <Li title="报单时间" content={created_at} />
          <br />
          <Li title="备注" content={comments} />
        </ul>
      </Card>
    )
  }

  const Li = (props) => {
    return (
      <li style={{ listStyleType: 'initial' }}>
        <div style={{ display: 'block', width: '100%' }}>
          <div style={{ float: 'left', width: 150 }}>{props.title}</div>
          <div style={{ float: 'right', width: 150, textAlign: 'right' }}>{props.content}</div>
        </div>
      </li>
    )
  }

  const IncomeDetail = () => {
    return (
      <Card
        bordered
        title="收入详情"
        style={detailCardStyle}
      >
        <ul>
          <Li title="基础价格" content={meta !== null ? meta.base_price : ''} />
          <br />
          <Li title={player_type.length !== 0 ? player_type[0].split(':$')[0] : '无数据'} content={player_type.length !== 0 ? player_type[0].split(':$')[1] : '无数据'} />
          <br />
          <Li title={player_num.length !== 0 ? player_num[0].split(':$')[0] : '无数据'} content={player_num.length !== 0 ? player_num[0].split(':$')[1] : '无数据'} />
          <br />
          <Li title={"大神TAG: " + (dashen_tag === true ? '是' : '否')} content={dashen_tag === true ? DASHEN_TAG_PRICE : 0} />
        </ul>
        <Divider />
        <h3 style={{ textAlign: 'right', fontWeight: 500 }}>最终单价($): <span className="highlight-number">{price}</span></h3>
        <Divider />
        <ul>
          <Li
            title="时长/局数"
            content={hours}
          />
        </ul>
        <Divider />
        <h3 style={{ textAlign: 'right', fontWeight: 500 }}>小计($): <span className="highlight-number">{price * hours + (price + OVERTIME_RATE) * overtime}</span></h3>
        <Divider />
        <h3 style={{ textAlign: 'right', fontWeight: 500 }}>用户当前折扣率: <span className="highlight-number">{discount}</span></h3>
        <Divider />
        <h2 style={{ textAlign: 'right', visibility: status === 1 ? 'visible' : 'hidden' }}>最终收入($): <span className="highlight-number">{amount}</span></h2>
      </Card>
    )
  }

  return (
    <div style={{ letterSpacing: '1px', fontFamily: 'Arial' }}>
      <h2>订单详情</h2>
      <Divider />
      <div className="status">
        <StatusView />
      </div>
      <Divider />
      <div className="detail-container">
        <div className="left-box">
          <div className="status-alert-section">
            <AlertView />
          </div>
          <div className="participants">
            <div className="user-box">
              <CustomerInfo />
            </div>
            <div className="player-box">
              <PlayerInfo />
            </div>
          </div>
          <div className="detail-info-box">
            <DetailInfo />
          </div>
        </div>
        <div className="right-box">
          <IncomeDetail />
        </div>
      </div>
      <Divider />
      <Paragraph>
        <pre>
          {footContent}
        </pre>
      </Paragraph>
    </div>
  )
}
