import { Component } from 'react'
import { Typography, Divider, Collapse } from 'antd'

import './payoff.css'

const { Panel } = Collapse
const { Title, Paragraph, Text, Link } = Typography

export default class Payoff extends Component {
  render() {
    return (
      <div className="payoff-page">
        <Typography>
          <div className="payoff-header">
            <Title>CKP工资发放规则</Title>
          </div>
          <div className="part1">
            <Paragraph>
              我们支持<Text code>美金</Text>，<Text code>加币</Text>，
              <Text code>人民币</Text>的三个币种
            </Paragraph>
            <Paragraph>
              <h5>发放方式：</h5>
              <ul>
                <li>
                  <Text>美金：Zelle，Venmo，Paypal</Text>
                </li>
                <li>
                  <Text>加币：EMT</Text>
                </li>
                <li>
                  <Text>人民币：微信 支付宝</Text>
                </li>
              </ul>
              <h5>工资的发放时间：</h5>
              <Paragraph>
                <ul>
                  <li>
                    每周美东时间<Text mark>周一开始到周四不等</Text>
                    发放上一周的工资。
                  </li>
                </ul>
              </Paragraph>
            </Paragraph>
            <h4>各种收款方式说明</h4>
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Zelle，Venmo，Paypal，EMT" key="1">
                <Paragraph>
                  每一周只会发放当前收入超过60美金的陪玩，如果未超过则会攒到下一周发放，如果下一周还不够60则会延续到下一周。直至每个月底会全部清0，月底不管当前收入多少，都会全部发放。加币汇率小于等于实时汇率，汇率根据公会换美金的实际情况。
                </Paragraph>
              </Panel>
              <Panel header="微信，支付宝" key="2">
                <Paragraph>
                  每周都会发放工资，不管金额是多少。人民币汇率小于等于实时汇率，汇率根据公会换美金的实际情况。
                  <br />
                  需要微信收款的陪玩
                  <Text strong>
                    请添加Kan-777并备注CKP+公会昵称，请一定要添加。
                  </Text>
                </Paragraph>
              </Panel>
              <div className="part1-des">
                <Text italic strong>
                  *请大家在个人中心里修改最适合自己的收款方式
                </Text>
                <br />
                收款方式修改格式：<Text code>收款方式</Text>+
                <Text code>收款账号</Text>+<Text code>收款名字</Text>,
                如果是微信收款修改为：<Text code>微信</Text>+
                <Text code>微信号</Text>
                <br />
                举例：Zelle 2001231213 MINGZE
              </div>
            </Collapse>
            <blockquote>
              <Text strong>
                陪玩中心中的当前收入为待结算金额，一旦变为0，说明你们的工资已发放，可以查询自己账户是否收到相对应的工资。如果当前收入清0了却没收到工资请私信啃啃。
              </Text>
            </blockquote>
            <pre>
              麻烦大家仔细阅读上述工资发放规则，不要再来询问上述已经说明的问题比如什么时候发工资，收款方式该给谁，我的工资还没有到。
              <br />
              发工资小哥很辛苦，每周大概要发100-150人左右的工资，还涉及到换钱的问题。如果急用钱，急需要这周工资，可以来私信
              <Text mark>啃啃</Text>会优先发放。
            </pre>
          </div>
        </Typography>
      </div>
    )
  }
}
