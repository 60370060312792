import storageUtils from "../utils/storageUtils"

const BASE = 'https://ckpclub.top:3000'

export default {
    playerReqLogin (username, password) {
        const config = {
            method: 'post',
            url: BASE + '/player_auth/sign_in',
            headers: {
                'login': username,
                'password': password
            }
        }
        return config
    },

    userReqLogin (username, password) {
        const config = {
            method: 'post',
            url: BASE + '/customer_auth/sign_in',
            headers: {
                'login': username,
                'password': password
            }
        }
        return config
    },

    changeInfo (email) {
        var FormData = require('form-data')
        var data = new FormData()
        data.append('player[email]', email)

        const config = {
            method: 'post',
            url: BASE + '/players/',
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            },
            data: data
        }
        return config
    },

    changePassword (password, confirm_password) {
        var FormData = require('form-data')
        var data = new FormData()
        data.append('password', password)
        data.append('confirm_password', confirm_password)

        const config = {
            method: 'post',
            url: BASE + '/players/password',
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            },
            data: data
        }

        return config
    },

    changePaymentType (payment_type) {
        var FormData = require('form-data')
        var data = new FormData()
        data.append('player[payment_type]', payment_type)

        const config = {
            method: 'post',
            url: BASE + '/players/',
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            },
            data: data
        }
        return config
    },

    getInvoices (pageSize, current) {
        const config = {
            method: 'get',
            url: BASE + '/invoices?pageSize=' + pageSize + '&current=' + current,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    getInvoicesByFilter (pageSize, current, filter) {
        const config = {
            method: 'get',
            url: BASE + '/invoices?pageSize=' + pageSize + '&current=' + current + '&filter=' + filter,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    getOrders (pageSize, current) {
        const config = {
            method: 'get',
            url: BASE + '/orders?pageSize=' + pageSize + '&current=' + current,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    getOrderDetail (id) {
        const config = {
            method: 'get',
            url: BASE + '/orders/' + id,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    // 创建订单 游戏报单
    reqCreateOrder (game_type, game_level, start_time, overtime, comments, hours, meta) {
        var FormData = require('form-data')
        var newData = new FormData()
        newData.append('order[game_type]', game_type)
        newData.append('order[game_level]', game_level)
        newData.append('order[start_time]', start_time)
        newData.append('order[overtime]', overtime)
        newData.append('order[comments]', comments)
        newData.append('order[hours]', hours)
        newData.append('order[meta]', meta)
        const config = {
            method: 'post',
            url: BASE + '/orders',
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            },
            data: newData
        }
        return config
    },

    // 创建订单 礼物报单
    reqCreateGift (start_time, comments, amount, gift_level) {
        var FormData = require('form-data')
        var newData = new FormData()
        newData.append('order[game_type]', 'Gift')
        newData.append('order[start_time]', start_time)
        newData.append('order[overtime]', 0)
        newData.append('order[comments]', comments)
        newData.append('order[hours]', amount)
        newData.append('order[game_level]', gift_level)

        const config = {
            method: 'post',
            url: BASE + '/orders',
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            },
            data: newData
        }

        return config
    },

    reqAdd (key, name, client) {
        const config = {
            method: 'get',
            url: BASE + '/orders/' + key + '/appoint?name=' + name + '&client=' + client,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    // 游戏报价详情 查找名字
    getPrice (game) {
        const config = {
            method: 'get',
            url: BASE + '/games/price_list?name=' + game,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    // 所有用户：搜索
    searchUsers (filter) {
        const config = {
            method: 'get',
            url: BASE + '/players/customers?pageSize=20&current=0&filter=' + filter,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    // 用户详情
    getCustomerDetail (id) {
        const config = {
            method: 'get',
            url: BASE + '/players/customers/' + id,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    // 订单下成员
    getInfoByOrderId (id) {
        const config = {
            method: 'get',
            url: BASE + '/orders/' + id + '/members',
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    // 基本信息
    reqInfo (identity) {
        const config = {
            method: 'get',
            url: BASE + '/' + identity,
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': 'Bearer',
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }
        return config
    },

    // 获取所有游戏
    reqAllPrices () {
        const config = {
            method: 'get',
            url: BASE + '/games',
            headers: {
                'Access-Token': storageUtils.getInfo('access-token'),
                'Token-Type': storageUtils.getInfo('token-type'),
                'Client': storageUtils.getInfo('client'),
                'Expiry': storageUtils.getInfo('expiry'),
                'Uid': storageUtils.getInfo('uid'),
            }
        }

        return config
    },

    uploadReportsWithoutImg (title, content) {
        var FormData = require('form-data')
        var data = new FormData()
        data.append('report[title]', title)
        data.append('report[content]', content)

        const config = {
            method: 'post',
            url: BASE + '/reports',
            headers: {

            },
            data: data
        }
        return config
    },

    uploadReportsWithImg (title, content, img) {
        var FormData = require('form-data')
        var data = new FormData()
        data.append('report[title]', title)
        data.append('report[content]', content)
        data.append('img', img)

        const config = {
            method: 'post',
            url: BASE + '/reports',
            headers: {

            },
            data: data
        }
        return config
    },
}