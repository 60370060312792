import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
/* 路由js文件 */
/* 使用react-router-dom 6.x.x 版本:
      Swicth -> Routes
      component -> element
      element的值要写成组件引用形式: {<Home />}, 而不是{Home}
      <Outlet /> -> 现在用于二级路由，写在父路由下，给子路由元素提供预留空间
      和其他一些细节变化 
*/

import Login from '../pages/login/Login'
import PlayerLogin from '../pages/login/PlayerLogin'
import CustomerLogin from '../pages/login/CustomerLogin'

import Player from '../pages/player/Player'
import PlayerDashboard from '../pages/player/Dashboard'
import Payroll from '../pages/player/Payroll'
import GameOrders from '../pages/orders/GameOrders'
import GameOrder from '../pages/orders/GameOrder'
import GiftOrders from '../pages/orders/GiftOrders'
import GameForm from '../pages/form/GameForm'
import GiftForm from '../pages/form/GiftForm'
import PayoffDoc from '../pages/extra/docs/payoff'
import Achievement from '../pages/extra/docs/achievement'

import User from '../pages/user/User'
import UserDashboard from '../pages/user/Dashboard'
import Wallet from '../pages/user/Wallet'

import Report from '../pages/report/Report'
import Callback from '../pages/callback/callback'

export default function IndexRouter () {
  return (
    <Router>
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route element={<PlayerLogin />} path="/player-login" />
        <Route element={<CustomerLogin />} path="/customer-login" />
        <Route element={<Player />} path="/player">
          <Route element={<PlayerDashboard />} path="dashboard" />
          <Route element={<Payroll />} path="payroll" />
          <Route element={<GameForm />} path="game-form" />
          <Route element={<GiftForm />} path="gift-form" />
          <Route element={<GameOrders />} path="game-orders" />
          <Route element={<GameOrder />} path="game-order/:id" />
          <Route element={<GiftOrders />} path="gift-orders" />
          <Route element={<Report />} path="reports" />
          <Route path="docs">
            <Route element={<PayoffDoc />} path="payoff" />
            <Route element={<Achievement />} path="achievement" />
          </Route>
        </Route>
        <Route element={<User />} path="/user">
          <Route element={<UserDashboard />} path="dashboard" />
          <Route element={<Wallet />} path="wallet" />
          <Route element={<GameOrders />} path="game-orders" />
          <Route element={<GiftOrders />} path="gift-orders" />
          <Route element={<Report />} path="reports" />
        </Route>
        <Route element={<Callback />} path="/callback" />
        <Route element={<Login />} path="/" />
      </Routes>
    </Router>
  )
}