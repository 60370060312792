import { Component } from 'react'
import { Form, Input, Image, Button, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import axios from 'axios'
import configs from '../../api/configs'

import '../css/report.css'
import '../css/button.css'

const key = 'updatable'

export default class Report extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: {}, //保存文件对象内容
      src: '', //保存图片的url
    }
  }

  onFinish = (values) => {
    message.loading({ content: '正在上传...', key })

    var config_upload_reports
    if (this.state.src === '') {
      console.log('无图片')
      config_upload_reports = configs.uploadReportsWithoutImg(
        values.title,
        values.content
      )
    } else {
      console.log('有图片')
      config_upload_reports = configs.uploadReportsWithImg(
        values.title,
        values.content,
        this.state.file
      )
    }
    axios(config_upload_reports)
      .then((response) => {
        message.success({ content: '已提交!', key, duration: 2 })
      })
      .catch((error) => {
        console.log(error)
        message.error({ content: '出错了: ' + error, key, duration: 2 })
      })
  }

  handleImgChange = (event) => {
    if (event.target.files.length === 0) {
      this.setState({
        file: {},
        src: '',
      })
    } else if (event.target.files.length === 1) {
      var file = event.target.files[0]
      let src
      // 匹配类型为image/开头的字符串
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        src = URL.createObjectURL(file)
      } else {
        message.error('图片上传只支持JPG/PNG格式,请重新上传!')
      }
      if (file.size / 1024 / 1024 > 5) {
        message.error('图片上传大小不要超过5MB,请重新上传!')
      }

      this.setState({
        file,
        src,
      })
    }
  }

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    }

    return (
      <div
        className="report-body"
        style={{ letterSpacing: '1px', fontFamily: 'Arial' }}>
        <div className="report-header">写下你的反馈</div>

        <div className="report-form-body">
          <Form {...layout} name="report-form" onFinish={this.onFinish}>
            <Form.Item
              label="主题"
              name="title"
              rules={[{ required: true, message: '请输入主题' }]}>
              <Input type="text" allowClear style={{ width: 400 }} />
            </Form.Item>
            <Form.Item
              label="内容"
              name="content"
              rules={[{ required: true, message: '请输入内容' }]}>
              <TextArea
                placeholder={'请填写内容'}
                style={{ width: 400, maxHeight: 100 }}
              />
            </Form.Item>
            <Form.Item label="图片" name="img">
              <Input
                allowClear
                type="file"
                onChange={this.handleImgChange}
                style={{ width: 400 }}
              />
            </Form.Item>
            <Form.Item label="预览" name="prev">
              <Image src={this.state.src} width={200} height={200} alt="" />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button
                className="action-btn-danger"
                size="large"
                htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
}
