/*
本地数据管理模块
*/
const USER_KEY = 'user_key'
const USER = 'user'
const IDENTITY = 'identity'
const ACCESS_TOKEN = 'access_token'
const HEADER_TOKEN = 'access-token'
const REFRESH_TOKEN = 'refresh_token'
const TOKEN_TYPE = 'token-type'
const CLIENT = 'client'
const EXPIRY = 'expiry'
const HEADER_EXPIRY = 'header_expiry'
const UID = 'uid'

export default {
    /* 保存user */
    saveUser (user) {
        localStorage.setItem(USER_KEY, JSON.stringify(user))
    },

    /* 读取user */
    getUser () {
        return JSON.parse(localStorage.getItem(USER_KEY) || '{}')
    },

    /* 删除user */
    removeUser () {
        localStorage.removeItem(USER_KEY)
    },

    /* 缓存基本信息 */
    saveDetail (info) {
        localStorage.setItem(USER, JSON.stringify(info))
    },

    /* 读取基本信息 */
    getDetail () {
        return JSON.parse(localStorage.getItem(USER) || '{}')
    },

    //
    saveIdentity (identity) {
        localStorage.setItem(IDENTITY, identity)
    },

    getIdentity () {
        return localStorage.getItem(IDENTITY) || '{}'
    },

    // 保存token
    saveToken (token) {
        localStorage.setItem(ACCESS_TOKEN, token)
    },

    saveHeaderToken (token) {
        localStorage.setItem(HEADER_TOKEN, token)
    },

    saveHeaderExpiry (expiry) {
        localStorage.setItem(HEADER_EXPIRY, expiry)
    },

    // 保存Refresh token
    saveRefreshToken (token) {
        localStorage.setItem(REFRESH_TOKEN, token)
    },

    // 保存token_type
    saveType (type) {
        localStorage.setItem(TOKEN_TYPE, type)
    },

    // 保存client信息
    saveClient (client) {
        localStorage.setItem(CLIENT, client)
    },

    // 保存expiry信息
    saveExpiry (expiry) {
        localStorage.setItem(EXPIRY, expiry)
    },

    // 保存uid
    saveUid (uid) {
        localStorage.setItem(UID, uid)
    },

    // 读取信息
    getInfo (key) {
        return localStorage.getItem(key) || '{}'
    },

    // 删除头部信息
    removeInfo () {
        localStorage.removeItem(ACCESS_TOKEN)
        localStorage.removeItem(TOKEN_TYPE)
        localStorage.removeItem(CLIENT)
        localStorage.removeItem(EXPIRY)
        localStorage.removeItem(UID)
    },
}