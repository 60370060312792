import { Component } from 'react'
import { Layout, Menu, message, Divider, Dropdown, Avatar, Modal } from 'antd'
import { Link, Outlet } from 'react-router-dom'

import {
  HomeOutlined,
  UserOutlined,
  FileOutlined,
  MessageOutlined,
  FormOutlined,
  MoneyCollectOutlined,
  GiftOutlined,
} from '@ant-design/icons'

import '../css/general.css'
import '../css/button.css'

import storageUtils from '../../utils/storageUtils'

const { Content, Sider } = Layout
const { SubMenu } = Menu

export default class Player extends Component {
  constructor(props) {
    super(props)
    this.state = {
      signout_visible: false,
    }
  }

  componentDidMount () {
    console.log('欢迎使用CKP陪玩系统')
  }

  render () {
    // 检查登录状态
    const user = storageUtils.getUser()
    //如果缓存中无user: 当前未登录
    if (!user || storageUtils.getIdentity() !== 'Player') {
      // 自动跳转至登录界面
      message.warning('请先登录!')
      window.location.href = '/'
    }

    const showSignOutModal = () => {
      this.setState({ signout_visible: true })
    }

    const hideSignOutModal = () => {
      this.setState({ signout_visible: false })
    }

    const onSignOut = () => {
      storageUtils.removeUser()
      window.location.href = '/'
    }

    const accountMenu = (
      <Menu>
        <Menu.Item key="player-center">
          <Link to="/player/dashboard">
            <span>陪玩中心</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="menu-game-form">
          <Link to="/player/game-form">
            <span>游戏报单</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="menu-gift-form">
          <Link to="/player/gift-form">
            <span>礼物报单</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="logout" danger onClick={showSignOutModal}>
          退出
        </Menu.Item>
      </Menu>
    )

    return (
      <Layout className="player-user-page">
        <div className="header">
          <div className="logo">
            <div style={{ marginLeft: '20px' }}>
              <p>CKP陪玩中心</p>
            </div>
          </div>
          <div className="account">
            <span>欢迎您, {storageUtils.getUser().name}</span>
            <Divider type="vertical" />
            <Dropdown overlay={accountMenu}>
              <Avatar
                size="large"
                icon={<UserOutlined />}
                style={{ backgroundColor: '#87d068' }}
              />
            </Dropdown>
          </div>
        </div>
        <Layout className="player-user-body">
          <Sider width={200} style={{ letterSpacing: '0.2em' }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={['dashboard']}
              style={{
                height: '100%',
                borderRight: 10,
                fontWeight: 500,
              }}>
              <Menu.Item key="dashboard" icon={<HomeOutlined />}>
                <Link to="/player/dashboard">
                  <span>首页</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="payroll" icon={<MoneyCollectOutlined />}>
                <Link to="/player/payroll">
                  <span>收款信息</span>
                </Link>
              </Menu.Item>
              <SubMenu key="form" icon={<FormOutlined />} title="报单">
                <Menu.Item key="game-form" icon={<FileOutlined />}>
                  <Link to="/player/game-form">
                    <span>游戏报单</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="gift-form" icon={<GiftOutlined />}>
                  <Link to="/player/gift-form">
                    <span>礼物报单</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/*<Menu.Item key="Search_Users" icon={<SearchOutlined />}>
                                <Link to='/player/search_users'>
                                    <span>搜索用户</span>
                                </Link>
                            </Menu.Item>*/}
              <Menu.Item key="game-orders" icon={<FileOutlined />}>
                <Link to="/player/game-orders">
                  <span>接单记录</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="gift-orders" icon={<GiftOutlined />}>
                <Link to="/player/gift-orders">
                  <span>礼物记录</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="reports" icon={<MessageOutlined />}>
                <Link to="/player/reports">
                  <span>意见反馈</span>
                </Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout
            style={{
              padding: '0 24px 24px',
            }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
        <Modal
          visible={this.state.signout_visible}
          title="退出"
          onOk={onSignOut}
          onCancel={hideSignOutModal}
          okText="确认"
          cancelText="取消">
          确定要退出吗？
        </Modal>
      </Layout>
    )
  }
}
