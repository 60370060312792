import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import axios from 'axios'
import configs from '../../api/configs'
import storageUtils from '../../utils/storageUtils'

import '../css/login.css'

const key = 'updatable'
const IDENTITY = 'Player'

const onFinish = (values) => {
  message.loading({ content: '登录中...', key })
  // 存储身份信息：陪玩
  storageUtils.saveIdentity(IDENTITY)

  var player_req_login = configs.playerReqLogin(values.username, values.password)
  axios(player_req_login)
    .then(
      response => {
        //保存本地登录信息
        var user = response.data.data
        storageUtils.saveUser(user)
        storageUtils.saveHeaderToken(response.headers['access-token'])
        storageUtils.saveType(response.headers['token-type'])
        storageUtils.saveClient(response.headers['client'])
        storageUtils.saveExpiry(response.headers['expiry'])
        storageUtils.saveUid(response.headers['uid'])

        //提示信息
        message.success({ content: '登录成功!', key, duration: 2 })

        //页面跳转
        window.location.href = '/player/dashboard'
      }
    ).catch(
      error => {
        console.log(error)
        message.error({ content: '账号密码错误，请重试.', key, duration: 2 })
      }
    )
}

export default function PlayerLogin () {
  return (
    <div className="login">
      <div className="player-customer-container">
        <h1 style={{ fontSize: '2em' }}>陪玩登录</h1>
        <Form
          name="player-login-form"
          onFinish={onFinish}
          style={{ fontSize: 15, marginLeft: 10, marginRight: 10 }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入用户名！' }]}
          >
            <Input placeholder='用户名' prefix={<UserOutlined />} size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码！' }]}
          >
            <Input.Password placeholder='密码' prefix={<LockOutlined />} size="large" />
          </Form.Item>

          <Form.Item style={{ marginTop: 40 }}>
            <Button type="primary" htmlType="submit" className="submit-button">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
