import React, { Component } from 'react'
import moment from 'moment'
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Divider,
  Cascader,
  Progress,
  notification,
  Switch,
} from 'antd'

import '../css/gameForm.css'

import axios from 'axios'
import configs from '../../api/configs'
import storageUtils from '../../utils/storageUtils'
import TextArea from 'antd/lib/input/TextArea'

const timeFormat = 'YYYY-MM-DD HH:mm:ss'

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      base_price: 0, // 基础游戏价格
      player_type: '',
      player_type_price: 0, // 陪玩类型价格影响量
      player_num: '',
      player_num_price: 0, // 陪玩个数价格影响量
      dashen_tag_disabled: true,
      key: '', // 创建的新订单key
      options_prices: [], // 价目表
      percent: 0, //上传进度
      status: 'normal', //上传状态
      loading: false,
    }
  }

  componentDidMount () {
    this.getAllPrices()
  }

  // 无加班时长的版本
  onFinish = (values) => {
    console.log(values)
    var userName = values.newUser
    var game_type = values.game[0]
    var game_level = values.game[1].split(':$')[0]
    var start = moment().format(timeFormat)
    var comments = values.comments
    var hours = values.hours
    var overtime = 0 // 加班时长固定为0，即无加班时长
    var meta = {
      base_price: this.state.base_price,
      price:
        this.state.base_price +
        this.state.player_type_price +
        this.state.player_num_price,
      game: values.game,
      player_type: values.player_type,
      player_num: values.player_num,
      player_level: this.state.dashen_tag_disabled
        ? false
        : values.player_level,
    }
    this.setState({
      percent: 0,
      status: 'normal',
      loading: true
    })

    var name = '{"name": "' + userName + '"}'
    var encoded_string = encodeURIComponent(name)
    const config_search = configs.searchUsers(encoded_string)
    axios(config_search)
      .then((response) => {
        if (response.data.success === true) {
          this.increase(25)
          // 创建订单
          const config_Order = configs.reqCreateOrder(
            game_type,
            game_level,
            start,
            overtime,
            comments,
            hours,
            JSON.stringify(meta)
          )
          axios(config_Order)
            .then((response) => {
              this.increase(25)
              var key = response.data.id.toString()
              this.setState({ key: key })
              // 添加用户
              const config_addUser = configs.reqAdd(key, userName, 'Customer')
              axios(config_addUser)
                .then((response) => {
                  this.increase(25)
                  // 添加陪玩
                  const config_addPlayer = configs.reqAdd(
                    key,
                    storageUtils.getUser().name,
                    'Player'
                  )
                  axios(config_addPlayer)
                    .then((response) => {
                      this.increase(25)
                      this.changeStatus('success')
                      message.success('提交成功!')
                      this.setState({ loading: false })
                    })
                    .catch((error) => {
                      console.log(error)
                      this.changeStatus('exception')
                      this.setState({ loading: false })
                      message.error('提交陪玩信息失败: ' + error)
                    })
                })
                .catch((error) => {
                  console.log(error)
                  this.changeStatus('exception')
                  this.setState({ loading: false })
                  message.error('提交用户信息失败: ' + error)
                })
            })
            .catch((error) => {
              console.log(error)
              this.changeStatus('exception')
              this.setState({ loading: false })
              message.error('创建订单失败: ' + error)
            })
        } else {
          this.changeStatus('exception')
          this.setState({ loading: false })
          message.warning('无效老板昵称，请确认输入正确后重试。')
        }
      })
      .catch((error) => {
        console.log(error)
        this.changeStatus('exception')
        this.setState({ loading: false })
        message.error('出错了: ' + error)
      })
  }

  /* 有加班时长的版本
  onFinish = (values) => {
    console.log(values)
    // 检查总时长是否大于加班时长
    if (values.hours >= values.overtime) {
      var userName = values.newUser
      var game_type = values.game[0]
      var game_level = values.game[1].split(':$')[0]
      var start = moment().format(timeFormat)
      var overtime = values.overtime.toString() // 加班时长
      var comments = values.comments
      var hours = (values.hours - values.overtime).toString() // 非加班时长=总时长-加班时长
      var meta = {
        base_price: this.state.base_price,
        price:
          this.state.base_price +
          this.state.player_type_price +
          this.state.player_num_price,
        game: values.game,
        player_type: values.player_type,
        player_num: values.player_num,
        player_level: this.state.dashen_tag_disabled
          ? false
          : values.player_level,
      }
      this.setState({
        percent: 0,
        status: 'normal',
        loading: true
      })
      // 验证老板昵称合法性
      var name = '{"name": "' + userName + '"}'
      var encoded_string = encodeURIComponent(name)
      var config_search = configs.searchUsers(encoded_string)
      axios(config_search)
        .then((response) => {
          if (response.data.success === true) {
            this.increase(25)
            // 创建订单
            const config_Order = configs.reqCreateOrder(
              game_type,
              game_level,
              start,
              overtime,
              comments,
              hours,
              JSON.stringify(meta)
            )
            axios(config_Order)
              .then((response) => {
                this.increase(25)

                var key = response.data.id.toString()
                this.setState({ key: key })

                // 添加用户
                const config_addUser = configs.reqAdd(key, userName, 'Customer')
                axios(config_addUser)
                  .then((response) => {
                    this.increase(25)

                    // 添加陪玩
                    const config_addPlayer = configs.reqAdd(
                      key,
                      storageUtils.getUser().name,
                      'Player'
                    )
                    axios(config_addPlayer)
                      .then((response) => {
                        this.increase(25)
                        this.changeStatus('success')
                        message.success('提交成功!')
                        this.setState({ loading: false })
                      })
                      .catch((error) => {
                        console.log(error)
                        this.changeStatus('exception')
                        this.setState({ loading: false })
                        message.error('提交陪玩信息失败: ' + error)
                      })
                  })
                  .catch((error) => {
                    console.log(error)
                    this.changeStatus('exception')
                    this.setState({ loading: false })
                    message.error('提交用户信息失败: ' + error)
                  })
              })
              .catch((error) => {
                console.log(error)
                this.changeStatus('exception')
                this.setState({ loading: false })
                message.error('创建订单失败: ' + error)
              })
          } else {
            this.changeStatus('exception')
            this.setState({ loading: false })
            message.warning('无效老板昵称，请确认输入正确后重试。')
          }
        })
        .catch((error) => {
          console.log(error)
          this.changeStatus('exception')
          this.setState({ loading: false })
          message.error('出错了: ' + error)
        })
    } else {
      message.warning('加班时长不能大于总时长!')
    }
  } */

  getAllPrices = () => {
    const config_all_prices = configs.reqAllPrices()
    axios(config_all_prices)
      .then((response) => {
        this.handlePrices(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handlePrices = (price_list) => {
    var options = []
    var total = price_list.length
    var i
    for (i = 0; i < total; i++) {
      // 构造children
      var childrenToAdd = []
      var j
      for (j = 0; j < price_list[i].prices.length; j++) {
        var prices = price_list[i].prices
        childrenToAdd.push({
          label: prices[j].level + '($' + prices[j].price + ')',
          value: prices[j].level + ':$' + prices[j].price,
        })
      }

      // 构造游戏
      var itemToAdd = {
        label: price_list[i].name,
        value: price_list[i].name,
        children: childrenToAdd,
      }

      options.push(itemToAdd)
    }

    var games = this.sortPrices(options, 'games')
    this.setState({ options_prices: games })
  }

  sortPrices = (options, type) => {
    var sorted = []
    if (type === 'games') {
      var i
      for (i = 0; i < options.length; i++) {
        if (options[i].value !== 'Gift') {
          sorted.push(options[i])
        }
      }
    } else if (type === 'gifts') {
      var i
      for (i = 0; i < options.length; i++) {
        if (options[i].value === 'Gift') {
          sorted.push(options[i])
        }
      }
    }
    return sorted
  }

  // 基础价格
  getBasePrice = (game) => {
    //console.log(game)
    if (game !== null && game.length !== 0) {
      this.setState({
        base_price: Number(game[1].split(':$')[1]),
      })
      if (game[0] === '娱乐项目' || game[0] === '代练' || game[0] === 'Gift') {
        this.setState({ dashen_tag_disabled: true })
      } else {
        this.setState({ dashen_tag_disabled: false })
      }
    } else {
      this.setState({
        base_price: 0,
      })
    }
  }

  // 改变价格-陪玩类型
  changePriceByType = (value) => {
    //console.log(value)
    if (value !== null && value.length !== 0) {
      this.setState({
        player_type: value[0].split(':$')[0],
        player_type_price: Number(value[0].split(':$')[1]),
      })
    } else {
      this.setState({
        player_type: '',
        player_type_price: 0,
      })
    }
  }

  // 改变价格-陪玩个数
  changePriceByNum = (value) => {
    //console.log(value)
    if (value !== null && value.length !== 0) {
      this.setState({
        player_num: value[0].split(':$')[0],
        player_num_price: Number(value[0].split(':$')[1]),
      })
    } else {
      this.setState({
        player_num: '',
        player_num_price: 0,
      })
    }
  }

  //增加进度条
  increase = (amount) => {
    let percent = this.state.percent + amount

    if (percent > 100) {
      percent = 100
    }

    this.setState({ percent }) // state中的变量和本地变量名相同时可用
  }

  //改变进度条状态
  changeStatus = (status) => {
    this.setState({ status: status })
  }

  // 消息提醒框
  openBalNotification = () => {
    notification.open({
      message: '重要',
      description:
        '请报单时注意填写陪玩类型/人数/是否为娱乐的信息，若未看到该选项请刷新界面哦。',
      duration: 10,
    })
  }

  render () {
    // 陪玩类型和陪玩个数与admin_sys->pending中相对应
    const options_player_types = [
      {
        value: '普通:$0',
        label: '普通(单价不变)',
      },
      {
        value: '银牌:$1',
        label: '银牌(单价+1)',
      },
      {
        value: '金牌:$2',
        label: '金牌(单价+2)',
      },
    ]

    const options_player_num = [
      {
        value: '单陪:$0',
        label: '单陪(单价不变)',
      },
      {
        value: '多陪:$-1',
        label: '多陪(单价-1)',
      },
    ]

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    }

    const validateMessages = {
      required: '请填写${label}!',
      types: {
        number: '请输入正确的${label}!',
      },
      number: {
        range: '${label} 必须在 ${min} 和 ${max} 之间',
      },
    }

    return (
      <div
        className="form-main"
        style={{ letterSpacing: '1px', fontFamily: 'Arial' }}>
        <div className="form-header">填写游戏报单</div>
        <div className="form-content">
          <div className="form-content-left">
            <Form
              {...layout}
              name="orders_making_form"
              onFinish={this.onFinish}
              validateMessages={validateMessages}>
              <Form.Item
                name="newUser"
                label="老板昵称"
                rules={[{ required: true }]}>
                <Input
                  allowClear
                  type="text"
                  placeholder={'老板的DC名称（不需要数字ID），EX: 啃啃'}
                  style={{ width: 400 }}
                />
              </Form.Item>
              <Form.Item
                name="game"
                label="游戏类型"
                rules={[{ required: true }]}>
                <Cascader
                  allowClear
                  options={this.state.options_prices}
                  placeholder="请选择"
                  onChange={(value) => this.getBasePrice(value)}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item
                name="player_level"
                label="大神TAG?"
                valuePropName="checked">
                <Switch disabled={this.state.dashen_tag_disabled} />
              </Form.Item>
              <Form.Item
                name="player_type"
                label="陪玩类型"
                rules={[{ required: true }]}>
                <Cascader
                  allowClear
                  options={options_player_types}
                  placeholder="请选择"
                  onChange={(value) => this.changePriceByType(value)}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item
                name="player_num"
                label="陪玩人数"
                rules={[{ required: true }]}>
                <Cascader
                  allowClear
                  options={options_player_num}
                  placeholder="请选择"
                  onChange={(value) => this.changePriceByNum(value)}
                  style={{ width: 200 }}
                />
              </Form.Item>

              <Form.Item label="当前单价($)">
                {this.state.base_price +
                  this.state.player_type_price +
                  this.state.player_num_price}{' '}
                (最终单价可能会由管理员调整)
              </Form.Item>
              <Form.Item
                name="hours"
                label="总时长/总局"
                rules={[{ required: true, type: 'number', min: 0, max: 99 }]}>
                <InputNumber style={{ width: 200 }} />
              </Form.Item>
              {/*
              <Form.Item
                name="overtime"
                label="加班时长/加班局数"
                rules={[{ required: true, type: 'number', min: 0, max: 99 }]}>
                <InputNumber
                  placeholder={'若无加班时长，填写0即可'}
                  style={{ width: 200 }}
                />
              </Form.Item>
              */}
              <Form.Item name="comments" label="备注">
                <TextArea
                  placeholder={'如果是自带老板的报单，请一定备注自带老板'}
                  style={{ width: 400, maxHeight: 100 }}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button
                  className="action-btn-danger"
                  size="large"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
            <Divider />
            <h3>上传进度:</h3>
            <Progress percent={this.state.percent} status={this.state.status} />
          </div>
        </div>
      </div>
    )
  }
}
