import './App.css'
import IndexRouter from './routers/IndexRouter'

function App () {
  return (
    <div className="App">
      <IndexRouter />
    </div>
  )
}

export default App
