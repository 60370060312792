import { Component } from 'react'
import {
  Descriptions,
  Input,
  message,
  Button,
  Card,
  Divider,
  List,
  Typography,
  Modal,
  Form,
  Space,
} from 'antd'

import axios from 'axios'
import configs from '../../api/configs'

import Relationship from './Relationship.js'
import '../css/dashboard.css'
import '../css/button.css'
import storageUtils from '../../utils/storageUtils'

const key = 'updatable'
const IDENTITY = 'players'
const data = [
  <Typography.Text>
    <Typography.Text mark>[2022-09-07]</Typography.Text> 新的成就文档
  </Typography.Text>,
  <Typography.Text>
    <Typography.Text mark>[2022-08-26]</Typography.Text> 新增订单详情界面
  </Typography.Text>,
  <Typography.Text>
    <Typography.Text mark>[2022-08-18]</Typography.Text> 更新收款信息界面
  </Typography.Text>,
  <Typography.Text>
    <Typography.Text mark>[2022-08-10]</Typography.Text> 报单新增大神TAG选项
  </Typography.Text>,
]

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      payment_type: '',
      created_at: '',
      balance: 0,
      income: 0,
      divide_rate: 0,
      loading: true,
      email_visib: false,
      pwd_visib: false,
      payment_type_visib: false,
      new_email: '',
      new_payment_type: '',
    }
  }

  componentDidMount () {
    // 获取基本信息
    this.getInfo()
  }

  // 获取基本信息
  getInfo = () => {
    var config_getInfo = configs.reqInfo(IDENTITY)
    axios(config_getInfo)
      .then((response) => {
        this.setState({
          loading: false,
          name: response.data.player.name,
          email: response.data.player.email,
          phone: response.data.player.phone,
          payment_type: response.data.player.payment_type,
          created_at:
            response.data.player.created_at.split('T')[0] +
            ' ' +
            response.data.player.created_at.split('T')[1].split('.')[0],
          balance: Number(response.data.salary.balance),
          income: Number(response.data.salary.income),
          divide_rate: Number(response.data.salary.divide_rate),
        })
        // 缓存基本信息
        storageUtils.saveDetail(response.data)
      })
      .catch((error) => {
        console.log(error)
        message.error('页面加载失败，请刷新界面或联系客服: ' + error)
      })
  }

  // 修改Email的回调函数
  onChangeEmail = () => {
    message.loading({ content: '提交中...', key, duration: 5 })

    var config_changeInfo = configs.changeInfo(this.state.new_email)
    axios(config_changeInfo)
      .then((response) => {
        message.success({ content: '修改成功，请重新登录！', key, duration: 2 })
        this.setState({
          email_visib: false,
        })
        //重新登录
        setTimeout(window.history.back(), 2000)
      })
      .catch((error) => {
        console.log(error)
        message.error({
          content: '提交失败，请重试或联系客服: ' + error,
          key,
          duration: 2,
        })
        this.setState({ email_visib: false })
      })
  }

  // 修改payment type的回调函数
  onChangePaymentType = () => {
    message.loading({ content: '提交中...', key })
    var config_change_payment_type = configs.changePaymentType(
      this.state.new_payment_type
    )
    axios(config_change_payment_type)
      .then((response) => {
        message.success({ content: '修改成功!', key, duration: 2 })
        this.setState({
          payment_type_visib: false,
          loading: true,
        })
        // 获取基本信息
        this.getInfo()
      })
      .catch((error) => {
        console.log(error)
        message.error({ content: '修改失败，请重试!', key, duration: 2 })
        this.setState({ payment_type_visib: false })
      })
  }

  // 显示修改Email的对话框
  showChangeEmail = () => {
    this.setState({
      email_visib: true,
      new_email: this.state.email,
    })
  }
  // 隐藏修改Email的对话框
  hideChangeEmail = () => {
    this.setState({ email_visib: false })
  }
  // 显示修改密码的对话框
  showChangePwd = () => {
    this.setState({ pwd_visib: true })
  }
  // 隐藏修改密码的对话框
  hideChangePwd = () => {
    this.setState({ pwd_visib: false })
  }
  // 处理可控组件Input Email
  handleEmail = (event) => {
    this.setState({ new_email: event.target.value })
  }
  //处理可控组件Input Payment Type
  handlePaymentType = (event) => {
    this.setState({ new_payment_type: event.target.value })
  }

  render () {
    const cardStyle = {
      width: '23.5%',
      marginLeft: '2%',
    }
    const cardHeadStyle = {
      background: '#f5f5f5',
      fontWeight: '700',
    }
    const cardBodyStyle = {
      color: '#f5222d',
      fontWeight: '600',
      fontSize: '1.3em',
      fontFamily: 'Arial',
    }
    const onFinish = (values) => {
      // 两次密码输入相同
      if (values.new_password === values.confirm_password) {
        message.loading({ content: '处理中...', key })

        var config_change_password = configs.changePassword(
          values.new_password,
          values.confirm_password
        )
        axios(config_change_password)
          .then((response) => {
            message.success({ content: '修改成功!', key, duration: 2 })
            this.setState({ pwd_visib: false })
          })
          .catch((error) => {
            console.log(error)
            message.error({
              content: '修改密码失败，请重试或联系客服: ' + error,
              key,
              duration: 2,
            })
            this.setState({ pwd_visib: false })
          })
      }
      // 两个密码不相同
      else {
        message.warning({
          content: '两次密码不相同, 请重新输入。',
          key,
          duration: 2,
        })
      }
    }

    return (
      <div className="dashboard-page">
        <div>
          <Descriptions
            bordered
            title="个人信息"
            extra={[
              <Button
                className="action-btn"
                key="change-email-btn"
                onClick={this.showChangeEmail}>
                修改邮箱
              </Button>,
              <Divider key={0} type="vertical" />,
              <Button
                className="action-btn-danger"
                key="change-pwd-btn"
                onClick={this.showChangePwd}>
                修改密码
              </Button>,
            ]}>
            <Descriptions.Item key={1} label="昵称">
              {this.state.name}
            </Descriptions.Item>
            <Descriptions.Item key={2} label="Email">
              {this.state.email === null ? '未提供' : this.state.email}
            </Descriptions.Item>
            <Descriptions.Item key={3} label="手机号">
              {this.state.phone === null ? '未提供' : this.state.phone}
            </Descriptions.Item>
            <Descriptions.Item key={4} label="入职时间">
              {this.state.created_at}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Divider />
        <div className="card-wrapper">
          <div style={{ width: '23.5%' }}>
            <Card
              title="当前收入"
              headStyle={cardHeadStyle}
              bodyStyle={cardBodyStyle}
              loading={this.state.loading}>
              ${this.state.balance}
            </Card>
          </div>
          <div style={cardStyle}>
            <Card
              title="累计收入"
              headStyle={cardHeadStyle}
              bodyStyle={cardBodyStyle}
              loading={this.state.loading}>
              ${this.state.income}
            </Card>
          </div>
          <div style={cardStyle}>
            <Card
              title="接单分成"
              headStyle={cardHeadStyle}
              bodyStyle={cardBodyStyle}
              loading={this.state.loading}
              extra={
                <Button
                  className="action-btn"
                  onClick={() => {
                    window.location.href = '/player/docs/achievement'
                  }}>
                  详情
                </Button>
              }>
              {this.state.divide_rate * 100}%
            </Card>
          </div>
          {/*
          <div style={cardStyle}>
            <Card
              title="提现方式"
              headStyle={cardHeadStyle}
              bodyStyle={cardBodyStyle}
              loading={this.state.loading}
              extra={
                <Button
                  className="action-btn"
                  onClick={() => {
                    this.setState({ payment_type_visib: true })
                  }}>
                  修改
                </Button>
              }>
              {this.state.payment_type}
            </Card>
          </div>
          */}
        </div>
        {/*
        <Divider />
        <h2>心动值</h2>
        <Relationship />
        */}
        <Divider />
        <Divider orientation="left">近期通知与更新</Divider>
        <List
          bordered
          dataSource={data}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        <Modal
          visible={this.state.email_visib}
          title="修改邮箱地址"
          onOk={this.onChangeEmail}
          onCancel={this.hideChangeEmail}
          okText="确认"
          cancelText="取消">
          <p>
            电子邮箱: &nbsp;
            <Input
              type="text"
              value={this.state.new_email}
              onChange={this.handleEmail}
              onPressEnter={this.onChangeEmail}
              style={{ width: 370, float: 'right' }}
            />
          </p>
        </Modal>
        <Modal
          title="修改密码"
          visible={this.state.pwd_visib}
          onCancel={this.hideChangePwd}
          footer={[
            <Button key="back" onClick={this.hideChangePwd}>
              取消
            </Button>,
          ]}>
          <Space>
            <Form name="change-pwd-form" onFinish={onFinish}>
              <Form.Item
                label="新密码"
                name="new_password"
                rules={[{ required: true, message: '请输入新密码!' }]}>
                <Input.Password placeholder="请输入新密码" allowClear />
              </Form.Item>
              <Form.Item
                label="确认密码"
                name="confirm_password"
                rules={[{ required: true, message: '请再次输入新密码!' }]}>
                <Input.Password placeholder="请确认密码" allowClear />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form>
          </Space>
        </Modal>
        <Modal
          visible={this.state.payment_type_visib}
          title="修改提现方式"
          onOk={this.onChangePaymentType}
          onCancel={() => {
            this.setState({ payment_type_visib: false })
          }}
          okText="确认"
          cancelText="取消">
          <p>请输入新的提现方式:</p>
          <Input
            type="text"
            value={this.state.new_payment_type}
            onChange={this.handlePaymentType}
            onPressEnter={this.onChangePaymentType}
          />
        </Modal>
      </div>
    )
  }
}
