import React from 'react'

import '../css/login.css'
import LOGO from '../../images/img1.jpg'

const navigateToPlayerLogin = () => {
  window.location.href = "/player-login"
}

const navigateToCustomerLogin = () => {
  window.location.href = "/customer-login"
}

export default function Login () {
  return (
    <div className="login">
      <div className="container">
        <img width={150} height={150} src={LOGO} alt="图片已失效"></img>
        <div className="login-btns">
          <h1 style={{ fontSize: '2em' }}>请选择登录方式</h1>
          <button onClick={navigateToCustomerLogin}>用户</button>
          <button onClick={navigateToPlayerLogin}>陪玩</button>
        </div>
      </div>
    </div>
  )
}
