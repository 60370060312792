import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { FaDiscord } from "react-icons/fa"

import axios from 'axios'
import configs from '../../api/configs'
import storageUtils from '../../utils/storageUtils'

import '../css/login.css'

const key = 'updatable'
const IDENTITY = 'Customer'
const DC_LOGIN_URL = 'https://discord.com/api/oauth2/authorize?client_id=836595889795366972&redirect_uri=https%3A%2F%2Fdashboard.ckpclub.top%2Fcallback&response_type=code&scope=identify%20email'

const onFinish = (values) => {
  message.loading({ content: '登录中...', key })
  // 存储身份信息：用户
  storageUtils.saveIdentity(IDENTITY)
  var customer_req_login = configs.userReqLogin(values.username, values.password)
  axios(customer_req_login)
    .then(
      response => {
        //保存本地登录信息
        var user = response.data.data
        storageUtils.saveUser(user)
        storageUtils.saveHeaderToken(response.headers['access-token'])
        storageUtils.saveType(response.headers['token-type'])
        storageUtils.saveClient(response.headers['client'])
        storageUtils.saveExpiry(response.headers['expiry'])
        storageUtils.saveUid(response.headers['uid'])

        //提示信息
        message.success({ content: '登录成功!', key, duration: 2 })

        //页面跳转
        window.location.href = '/user/dashboard'
      }
    ).catch(
      error => {
        console.log(error)
        message.error({ content: '账号密码错误，请重试.', key, duration: 2 })
      }
    )
}

const loginByDC = () => {
  // 存储身份信息：用户
  storageUtils.saveIdentity(IDENTITY)
  // 跳转至DC授权登录
  window.location.href = DC_LOGIN_URL
}

export default function CustomerLogin () {
  return (
    <div className="login">
      <div className="player-customer-container">
        <h1 style={{ fontSize: '2em' }}>客户登录</h1>
        <Form
          name="player-login-form"
          onFinish={onFinish}
          style={{ fontSize: 15, marginLeft: 10, marginRight: 10 }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入用户名！' }]}
          >
            <Input placeholder='用户名' prefix={<UserOutlined />} size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码！' }]}
          >
            <Input.Password placeholder='密码' prefix={<LockOutlined />} size="large" />
          </Form.Item>

          <Form.Item style={{ marginTop: 40 }}>
            <Button type="primary" htmlType="submit" className="submit-button">
              登录
            </Button>
          </Form.Item>
        </Form>
        <Button type="primary" className="dc-btn" onClick={loginByDC}>
          Discord
        </Button>
      </div>
    </div>
  )
}
