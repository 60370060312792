import { Component } from "react"
import { Layout, Menu, message, Divider, Dropdown, Avatar, Modal } from 'antd'
import { Link, Outlet } from 'react-router-dom'

import { UserOutlined, HomeOutlined, MoneyCollectOutlined, GiftOutlined, FileOutlined, MessageOutlined } from '@ant-design/icons'

import '../css/general.css'

import LOGO from '../../images/CKP_logo_proc.jpg'

import storageUtils from '../../utils/storageUtils'

const { Content, Sider } = Layout
const IDENTITY = 'Customer'

export default class User extends Component {
    constructor(props) {
        super(props)
        this.state = {
            signout_vtisible: false,
        }
    }

    componentDidMount () {
        console.log('欢迎使用CKP用户系统')
    }

    render () {
        const user = storageUtils.getUser()
        //如果缓存中无user，说明当前未登录
        if (!user || storageUtils.getIdentity() !== IDENTITY) {
            // 自动跳转至登录界面
            message.error('请先登录!')
            window.location.href = '/'
        }

        const showSignOutModal = () => {
            this.setState({ signout_vtisible: true, })
        }

        const hideSignOutModal = () => {
            this.setState({ signout_vtisible: false, })
        }

        const onSignOut = () => {
            storageUtils.removeUser()
            window.location.href = '/'
        }

        const accountMenu = (
            <Menu>
                <Menu.Item>
                    <Link to='/user/dashboard'>
                        <span>用户中心</span>
                    </Link>
                </Menu.Item>
                <Menu.Item danger onClick={showSignOutModal}>
                    退出
                </Menu.Item>
            </Menu>
        )

        return (
            <Layout className='player-user-page'>
                <div className="header">
                    <div className="logo">
                        <div style={{ marginLeft: "20px" }}>
                            <p>CKP用户中心</p>
                        </div>
                    </div>
                    <div className="account">
                        <span>欢迎您, {storageUtils.getUser().name}</span>
                        <Divider type="vertical" />
                        <Dropdown overlay={accountMenu}>
                            <Avatar size="large" icon={<UserOutlined />} />
                        </Dropdown>
                    </div>
                </div>
                <Layout className="player-user-body">
                    <Sider width={200} style={{ letterSpacing: "0.2em" }}>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['dashboard']}
                            style={{
                                height: '100%',
                                borderRight: 10,
                            }}
                        >
                            <Menu.Item key="dashboard" icon={<HomeOutlined />}>
                                <Link to='/user/dashboard'>
                                    <span>首页</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="wallet" icon={<MoneyCollectOutlined />}>
                                <Link to='/user/wallet'>
                                    <span>钱包</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="game-orders" icon={<FileOutlined />}>
                                <Link to='/user/game-orders'>
                                    <span>下单记录</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="gift-orders" icon={<GiftOutlined />}>
                                <Link to='/user/gift-orders'>
                                    <span>打赏记录</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="report" icon={<MessageOutlined />}>
                                <Link to='/user/reports'>
                                    <span>意见反馈</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout
                        style={{
                            padding: '0 24px 24px',
                        }}
                    >
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
                <Modal
                    visible={this.state.signout_vtisible}
                    title="退出"
                    onOk={onSignOut}
                    onCancel={hideSignOutModal}
                    okText="确认"
                    cancelText="取消"
                >
                    确定要退出吗？
                </Modal>
            </Layout>
        )
    }
}