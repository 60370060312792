import { Component } from "react"
import { message, Descriptions, Badge, Divider, DatePicker, Menu, Space, Button, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'

import '../css/payroll.css'
import '../css/button.css'

import axios from 'axios'
import configs from '../../api/configs'
const { RangePicker } = DatePicker
const dateFormat = 'YYYYMMDD'
const PAGE_SIZE = 50
const columns = [
    {
        title: '数额($)',
        dataIndex: 'amount',
        key: 'amount'
    },
    {
        title: '描述',
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: '生成时间(美东)',
        dataIndex: 'created_at',
        key: 'created_at'
    }
]

export default class Wallet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            columns: [],
            invoices: [],
            sel_invoices: [],
            menu_key: 'charge',
            balance: 0, // 当前余额
            consumption: 0, // 累计充值
            startDate: 0,
            endDate: 0,
        }
    }

    componentDidMount () {
        // 获取基本信息
        this.getInfo()
        // 获取收据
        this.getInvoicesByType(this.state.menu_key)
    }

    // 获取基本信息
    getInfo = () => {
        const config_getInfo = configs.reqInfo('customers')
        axios(config_getInfo)
            .then(
                response => {
                    this.setState({
                        balance: response.data.balance.amount,
                        consumption: response.data.balance.consumption
                    })
                }
            ).catch(
                error => {
                    console.log(error)
                    message.error('请求获取基本信息失败，请重新登录或联系客服。')
                }
            )
    }

    // 获取所有账单通过bill type
    getInvoicesByType = (type) => {
        var filterString = '{"bill_type": "' + type + '"}'
        var encoded_filter_string = encodeURIComponent(filterString)
        var config_get_invoices = configs.getInvoicesByFilter(
            PAGE_SIZE,
            0,
            encoded_filter_string
        )
        axios(config_get_invoices)
            .then((response) => {
                // 处理账单
                this.handleInvoices(response.data.data)
                var i
                for (i = 1; i < Math.ceil(response.data.total / PAGE_SIZE); i++) {
                    config_get_invoices = configs.getInvoicesByFilter(
                        PAGE_SIZE,
                        i,
                        encoded_filter_string
                    )
                    axios(config_get_invoices)
                        .then((response) => {
                            // 处理账单
                            this.handleInvoices(response.data.data)
                        })
                        .catch((error) => {
                            console.log(error)
                            message.error('请求获取账单失败，请重试!')
                        })
                }
            })
            .catch((error) => {
                console.log(error)
                message.error('请求获取账单失败，请重试!')
            })
    }

    // 处理账单数据
    handleInvoices = (dataSource) => {
        var invoicesToAdd = []
        var i
        for (i = 0; i < dataSource.length; i++) {
            invoicesToAdd.push({
                key: dataSource[i].id,
                amount: Number(dataSource[i].amount),
                bill_type: dataSource[i].bill_type,
                description: dataSource[i].description,
                created_at: dataSource[i].created_at.split('T')[0] + ' - ' + dataSource[i].created_at.split('T')[1].split('.')[0]
            })
        }
        var new_invoices = this.state.invoices.concat(invoicesToAdd)
        this.setState({
            loading: false,
            invoices: new_invoices,
            sel_invoices: new_invoices
        })
    }

    // 筛选账单的方法
    filterInvoices = () => {
        if (this.state.startDate !== 0) {
            var sel_invoices = []
            this.state.invoices.forEach((obj) => {
                if (this.compareDate(obj)) {
                    sel_invoices.push(obj)
                }
            })
            this.setState({ sel_invoices })
        } else {
            message.info('请选择日期!')
        }
    }

    // 禁用时间段：仅可选取今日及今日之前的日期
    disabledDate = (current) => {
        return current > moment().endOf('day')
    }

    // 读取开始时间和结束时间
    setDate = (value, mode) => {
        if (value === null) {
            message.info('请选择日期!')
        }
        else {
            this.setState({
                startDate: value[0].format(dateFormat),
                endDate: value[1].format(dateFormat)
            })
        }
    }

    // menu的onClick回调
    onChangeMenuKey = (e) => {
        this.setState({
            menu_key: e.key,
            invoices: [],
            sel_invoices: [],
            loading: true,
        }, () => this.getInvoicesByType(e.key))
    }

    // 判断一个账单是否在时间区间内，如果是返回true，否则返回false
    compareDate = (bill) => {
        var date = parseInt(moment(bill.created_at.split(' - ')[0]).format(dateFormat))
        return date >= this.state.startDate && date <= this.state.endDate
    }

    render () {
        return (
            <div className='payroll-page'>
                <div className='account'>
                    <Descriptions title='钱包' bordered>
                        <Descriptions.Item label='钱包类型'>用户</Descriptions.Item>
                        <Descriptions.Item label='当前余额($)'>{this.state.balance}</Descriptions.Item>
                        <Descriptions.Item label='累计充值($)'>{this.state.consumption}</Descriptions.Item>
                        <Descriptions.Item label='余额状态'>{this.state.balance < 0 ? <Badge status='error' text='欠费' /> : <Badge status='success' text='正常' />}</Descriptions.Item>
                    </Descriptions>
                </div>
                <Divider />
                <div className='statement'>
                    <Menu mode="horizontal" defaultSelectedKeys={["charge"]} onClick={(e) => this.onChangeMenuKey(e)}>
                        <Menu.Item key="charge">
                            <span>充值</span>
                        </Menu.Item>
                        <Menu.Item key="coupon">
                            <span>代金券</span>
                        </Menu.Item>
                    </Menu>
                    <div style={{ marginTop: 24 }}>
                        <Space direction="horizontal" size={14}>
                            <RangePicker
                                disabledDate={(current) => this.disabledDate(current)}
                                onChange={(value, mode) => this.setDate(value, mode)}
                                allowClear
                            />
                            <Space direction="horizontal" size={14}>
                                <Button
                                    className="action-btn"
                                    icon={<SearchOutlined />}
                                    onClick={() => this.filterInvoices()}>
                                    搜索
                        </Button>
                                <Button
                                    className="action-btn"
                                    onClick={() => this.setState({ sel_invoices: this.state.invoices })}>
                                    重置
                        </Button>
                            </Space>
                        </Space>
                        <br />
                        <br />
                        <Table
                            dataSource={this.state.sel_invoices}
                            columns={columns}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }
}