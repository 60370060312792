import { Component } from 'react'
import moment from 'moment'
import {
  Form,
  Button,
  Input,
  InputNumber,
  Cascader,
  message,
  Divider,
  Progress,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import '../css/giftForm.css'
import '../css/button.css'

import axios from 'axios'
import configs from '../../api/configs'
import storageUtils from '../../utils/storageUtils'

const timeFormat = 'YYYY-MM-DD HH:mm:ss'

export default class GiftForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      price: 0, // 礼物单价
      gift_id: 0, // 新礼物单号
      options: [], //价目表
      percent: 0, //上传进度
      status: 'normal', //上传状态
      loading: false,
    }
  }

  componentDidMount () {
    this.getAllPrices()
  }

  // 提交礼物表单
  onFinish = (values) => {
    var customer = values.customer
    var gift_level = values.gift_level[1]
    var amount = values.amount
    var comments = values.comments

    this.setState({
      percent: 0,
      status: 'normal',
      loading: true,
    })

    // 验证老板昵称合法性
    var name = '{"name": "' + customer + '"}'
    var encoded_string = encodeURIComponent(name)
    var config_search = configs.searchUsers(encoded_string)
    axios(config_search)
      .then((response) => {
        if (response.data.success === true) {
          this.increase(25)

          // 创建礼物订单
          const config_createGift = configs.reqCreateGift(
            moment().format(timeFormat),
            comments,
            amount,
            gift_level
          )
          axios(config_createGift)
            .then((response) => {
              this.increase(25)

              // 获取当前礼物id
              var id = response.data.id
              this.setState({ gift_id: id })

              // 添加用户
              const config_addUser = configs.reqAdd(id, customer, 'Customer')
              axios(config_addUser)
                .then((response) => {
                  this.increase(25)

                  // 添加陪玩
                  const config_addPlayer = configs.reqAdd(
                    id,
                    storageUtils.getUser().name,
                    'Player'
                  )
                  axios(config_addPlayer)
                    .then((response) => {
                      this.increase(25)
                      this.changeStatus('success')
                      this.setState({ loading: false })
                      message.success('上传成功！')
                    })
                    .catch((error) => {
                      console.log(error)
                      this.changeStatus('exception')
                      this.setState({ loading: false })
                      message.error('提交陪玩信息失败，请检查网络环境后重试。')
                    })
                })
                .catch((error) => {
                  console.log(error)
                  this.changeStatus('exception')
                  this.setState({ loading: false })
                  message.error('提交用户信息失败，请检查网络环境后重试。')
                })
            })
            .catch((error) => {
              console.log(error)
              this.changeStatus('exception')
              this.setState({ loading: false })
              message.error('创建订单失败，请检查登录状态后重试。')
            })
        } else {
          this.changeStatus('exception')
          this.setState({ loading: false })
          message.warning('无效老板昵称，请确认输入正确后重试。')
        }
      })
      .catch((error) => {
        console.log(error)
        this.changeStatus('exception')
        this.setState({ loading: false })
        message.error('请求出错，请检查登录状态后重试。')
      })
  }

  getAllPrices = () => {
    const config_all_prices = configs.reqAllPrices()
    axios(config_all_prices)
      .then((response) => {
        this.handlePrices(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handlePrices = (price_list) => {
    var options = []
    var total = price_list.length
    var i
    for (i = 0; i < total; i++) {
      // 构造children
      var childrenToAdd = []
      var j
      for (j = 0; j < price_list[i].prices.length; j++) {
        var prices = price_list[i].prices
        childrenToAdd.push({
          label: prices[j].level,
          value: prices[j].level,
        })
      }

      // 构造游戏
      var itemToAdd = {
        label: price_list[i].name,
        value: price_list[i].name,
        children: childrenToAdd,
      }

      options.push(itemToAdd)
    }

    var gifts = this.sortPrices(options, 'gifts')
    this.setState({ options: gifts })
  }

  sortPrices = (options, type) => {
    var sorted = []
    if (type === 'games') {
      var i
      for (i = 0; i < options.length; i++) {
        if (options[i].value !== 'Gift') {
          sorted.push(options[i])
        }
      }
    } else if (type === 'gifts') {
      var i
      for (i = 0; i < options.length; i++) {
        if (options[i].value === 'Gift') {
          sorted.push(options[i])
        }
      }
    }
    return sorted
  }

  handlePrice = (value) => {
    var gift_level = value[1]

    const config_getPrice = configs.getPrice('Gift')
    axios(config_getPrice)
      .then((response) => {
        response.data.forEach((obj) => {
          if (obj.level === gift_level) {
            this.setState({ price: obj.price })
          }
        })
      })
      .catch((error) => {
        console.log(error)
        message.error('计算单价失败，请检查网络环境。')
      })
  }

  //增加进度条
  increase = (amount) => {
    let percent = this.state.percent + amount

    if (percent > 100) {
      percent = 100
    }

    this.setState({ percent }) // state中的变量和本地变量名相同时可用
  }

  //改变进度条状态
  changeStatus = (status) => {
    this.setState({ status: status })
  }

  render () {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    }

    const validateMessages = {
      required: '请填写${label}!',
      types: {
        number: '请输入正确的${label}!',
      },
      number: {
        range: '${label} 必须在 ${min} 和 ${max} 之间',
      },
    }

    return (
      <div
        className="gift-main"
        style={{ letterSpacing: '1px', fontFamily: 'Arial' }}>
        <div className="gift-header">填写礼物报单</div>
        <div className="gift-content">
          <Form
            {...layout}
            name="nest-messages"
            onFinish={this.onFinish}
            validateMessages={validateMessages}>
            <Form.Item
              name="customer"
              label="老板昵称"
              rules={[{ required: true }]}>
              <Input
                type="text"
                placeholder={'老板的DC名称（不需要数字ID），EX：啃啃'}
                style={{ width: 400 }}
              />
            </Form.Item>
            <Form.Item
              name="gift_level"
              label="礼物名称"
              rules={[{ required: true }]}>
              <Cascader
                options={this.state.options}
                placeholder="请选择"
                onChange={(value, selectedOptions) => this.handlePrice(value)}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label="单价($)"
              rules={[{ required: true, type: 'number' }]}>
              {this.state.price}
            </Form.Item>
            <Form.Item
              name="amount"
              label="数量"
              rules={[{ required: true, type: 'number', min: 0, max: 999 }]}>
              <InputNumber precision={0} style={{ width: 200 }} />
            </Form.Item>
            <Form.Item name="comments" initialValue="礼物报单" label="备注">
              <TextArea style={{ width: 400, maxHeight: 100 }} />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button
                className="action-btn-danger"
                size="large"
                htmlType="submit"
                loading={this.state.loading}
              >
                提交
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <h3>上传进度：</h3>
          <Progress percent={this.state.percent} status={this.state.status} />
        </div>
      </div>
    )
  }
}
