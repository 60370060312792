import React from 'react'
import 'antd/dist/antd.css'
import { message, Result } from 'antd'
import { Component } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import axios from 'axios'
import storageUtils from '../../utils/storageUtils'

const BASE = 'https://ckpclub.top:3000/'
const key = 'updatable'

export default class Callback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      auth_code: '',
    }
  }

  // 加载界面后执行一次
  componentDidMount() {
    message.loading({ content: '授权登录处理中(0/2)...', key })

    var url = new String(window.location.href)
    var param = new String(url.split('?')[1])
    var code = new String(param.split('=')[1])
    console.log('欢迎来到CKP公会。')
    this.setState({ auth_code: code })

    // 发送异步ajax请求：提取code，并用code向服务器换取token
    const config_exchange_code = {
      method: 'get',
      url: BASE + '/exchange_code?code=' + code,
    }

    // 交换code
    axios(config_exchange_code)
      .then(function (response) {
        message.loading({ content: '授权登录处理中(1/2)...', key })

        storageUtils.saveToken(response.data['access_token'])
        storageUtils.saveRefreshToken(response.data['refresh_token'])
        storageUtils.saveExpiry(response.data['expires_in'])
        //获取用户信息
        const config_get_user = {
          method: 'get',
          url: BASE + '/user_info?user=Customer',
          headers: {
            Authorization: storageUtils.getInfo('access_token'),
          },
        }
        axios(config_get_user)
          .then(function (response) {
            message.loading({ content: '授权登录处理中(2/2)...', key })

            storageUtils.saveUser(response.data)
            storageUtils.saveHeaderToken(response.headers['access-token'])
            storageUtils.saveType(response.headers['token-type'])
            storageUtils.saveClient(response.headers['client'])
            storageUtils.saveExpiry(response.headers['expiry'])
            storageUtils.saveUid(response.headers['uid'])

            window.location.href = '/user/dashboard'
          })
          .catch(function (error) {
            console.log(error)

            message.error({
              content: '登陆失败，请重试(1/2)！',
              key,
              duration: 1,
            })
            window.location.href = '/'
          })
      })
      .catch(function (error) {
        console.log(error)

        message.error({ content: '授权失败(0/2)！', key, duration: 1 })
        window.location.href = '/'
      })
  }

  render() {
    return (
      <div>
        <Result icon={<LoadingOutlined />} title="授权登录处理中，请稍后..." />
      </div>
    )
  }
}
