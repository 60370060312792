import { Component } from 'react'
import moment from 'moment'
import { SearchOutlined } from '@ant-design/icons'
import {
  Tag,
  Modal,
  Button,
  Descriptions,
  Table,
  Divider,
  Input,
  message,
  Typography,
  DatePicker,
  Space,
} from 'antd'

import axios from 'axios'
import configs from '../../api/configs'

import '../css/payroll.css'
import '../css/button.css'

const key = 'updatable'
const IDENTITY = 'players'
const PAGE_SIZE = 20
const { Text } = Typography
const { RangePicker } = DatePicker
const dateFormat = 'YYYYMMDD'

export default class Payroll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      cash_invoices: [], // 提现收据
      filtered_cash_invoices: [],
      loading: true,
      balance: 0, // 当前收入
      income: 0, // 累计收入
      payment_type: '', //打款方式
      new_payment_type: '',
      visible: false,
      startDate: 0,
      endDate: 0,
    }
  }

  componentDidMount () {
    // 获取基本信息
    this.getInfo()
    // 获取账单
    this.getCashInvoices()

    const columns = [
      {
        title: '时间(美东)',
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: '金额($)',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: '备注',
        dataIndex: 'description',
        key: 'description',
      },
    ]

    this.setState({ columns: columns })
  }

  // 获取基本信息
  getInfo = () => {
    const config_getInfo = configs.reqInfo(IDENTITY)
    axios(config_getInfo)
      .then((response) => {
        this.setState({
          balance: response.data.salary.balance,
          income: response.data.salary.income,
          payment_type: response.data.player.payment_type,
        })
      })
      .catch((error) => {
        console.log(error)
        message.error('请求获取基本信息失败，请稍后重试!')
      })
  }

  // 获取所有账单
  getCashInvoices = () => {
    var filterString = '{"bill_type": "cash"}'
    var encoded_filter_string = encodeURIComponent(filterString)
    var config_get_cash_invoices = configs.getInvoicesByFilter(
      PAGE_SIZE,
      0,
      encoded_filter_string
    )
    axios(config_get_cash_invoices)
      .then((response) => {
        // 处理账单
        this.handleInvoices(response.data.data)
        var i
        for (i = 1; i < Math.ceil(response.data.total / PAGE_SIZE); i++) {
          config_get_cash_invoices = configs.getInvoicesByFilter(
            PAGE_SIZE,
            i,
            encoded_filter_string
          )
          axios(config_get_cash_invoices)
            .then((response) => {
              // 处理账单
              this.handleInvoices(response.data.data)
            })
            .catch((error) => {
              console.log(error)
              message.error('请求获取账单失败，请重试!')
            })
        }
      })
      .catch((error) => {
        console.log(error)
        message.error('请求获取账单失败，请重试!')
      })
  }

  // 处理账单数据
  handleInvoices = (dataSource) => {
    var invoicesToAdd = []
    var i
    for (i = 0; i < dataSource.length; i++) {
      invoicesToAdd.push({
        key: dataSource[i].id,
        amount: Number(dataSource[i].amount),
        description: dataSource[i].description,
        created_at:
          dataSource[i].created_at.split('T')[0] +
          ' - ' +
          dataSource[i].created_at.split('T')[1].split('.')[0],
        date: dataSource[i].created_at.split('T')[0],
      })
    }
    var cash_invoices = this.state.cash_invoices.concat(invoicesToAdd)
    this.setState({
      loading: false,
      cash_invoices,
      filtered_cash_invoices: cash_invoices,
    })
  }

  // 修改payment type的回调函数
  onChangePaymentType = () => {
    message.loading({ content: '提交中...', key, duration: 5 })

    var payment_type = this.state.new_payment_type

    const config_change_payment_type = configs.changePaymentType(payment_type)
    axios(config_change_payment_type)
      .then((response) => {
        message.success({ content: '修改成功!', key, duration: 2 })

        // 获取基本信息
        this.getInfo()
        this.setState({ visible: false })
      })
      .catch((error) => {
        console.log(error)
        message.error({ content: '修改失败，请重试!', key, duration: 2 })
        this.setState({ visible: false })
      })
  }

  handlePaymentType = (event) => {
    this.setState({ new_payment_type: event.target.value })
  }

  filterInvoices = () => {
    if (this.state.startDate !== 0) {
      var invoices = []
      this.state.cash_invoices.forEach((obj) => {
        if (this.compareDate(obj)) {
          invoices.push(obj)
        }
      })
      this.setState({
        filtered_cash_invoices: invoices,
      })
    } else {
      message.info('请选择日期!')
    }
  }

  // 禁用时间段：仅可选取今日及今日之前的日期
  disabledDate = (current) => {
    return current > moment().endOf('day')
  }

  // 读取开始时间和结束时间
  setDate = (value, mode) => {
    if (value === null) {
      message.info('请选择日期!')
    } else {
      this.setState({
        startDate: value[0].format(dateFormat),
        endDate: value[1].format(dateFormat),
      })
    }
  }

  // 判断一个账单是否在时间区间内，如果是返回true，否则返回false
  compareDate = (bill) => {
    var date = parseInt(moment(bill.date.split(' - ')[0]).format(dateFormat))
    var start = this.state.startDate
    var end = this.state.endDate

    return date >= start && date <= end
  }

  tableSummary = (pageData) => {
    let totalAmount = 0

    pageData.forEach(({ amount }) => {
      totalAmount += amount
      totalAmount = Number(totalAmount.toFixed(3))
    })

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell>合计($)</Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text type="danger">{totalAmount}</Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  render () {
    return (
      <div className="payroll-page">
        <div className="account-info">
          <Descriptions
            title="我的钱包"
            bordered
            contentStyle={{
              fontWeight: '550',
              fontFamily: 'Arial',
              color: '#ff4d4f',
            }}
            labelStyle={{ fontWeight: '600' }}>
            <Descriptions.Item label="当前收入($)">
              {this.state.balance}
            </Descriptions.Item>
            <Descriptions.Item label="累计收入($)">
              {this.state.income}
            </Descriptions.Item>
            <Descriptions.Item label="收款方式">
              {this.state.payment_type}
              &nbsp;&nbsp;&nbsp;
              <Button
                className="action-btn"
                size="small"
                onClick={() => {
                  this.setState({ visible: true })
                }}>
                修改
              </Button>
              <Divider type="vertical" />
              <Modal
                visible={this.state.visible}
                title="修改工资发放方式"
                onOk={this.onChangePaymentType}
                onCancel={() => {
                  this.setState({ visible: false })
                }}
                okText="确认"
                cancelText="取消">
                <p>请输入工资发放方式:</p>
                <Input
                  type="text"
                  value={this.state.new_payment_type}
                  onChange={this.handlePaymentType}
                  onPressEnter={this.onChangePaymentType}
                />
              </Modal>
              <Button
                className="action-btn"
                size="small"
                onClick={() => {
                  window.location.href = '/player/docs/payoff'
                }}>
                详情
              </Button>
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Divider />
        <h3>提现记录</h3>
        <div className="cash-statement">
          <Space direction="horizontal" size={14}>
            <RangePicker
              disabledDate={this.disabledDate}
              onChange={this.setDate}
              allowClear
            />
            <Space direction="horizontal" size={14}>
              <Button
                className="action-btn"
                icon={<SearchOutlined />}
                onClick={this.filterInvoices}>
                搜索
              </Button>
              <Button
                className="action-btn"
                onClick={() => {
                  this.setState({
                    filtered_cash_invoices: this.state.cash_invoices,
                  })
                }}>
                重置
              </Button>
            </Space>
          </Space>
          <br />
          <br />
          <Table
            dataSource={this.state.filtered_cash_invoices}
            columns={this.state.columns}
            summary={(pageData) => this.tableSummary(pageData)}
          />
        </div>
      </div>
    )
  }
}
