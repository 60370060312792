import { Component } from "react"
import { Table, message, Badge } from 'antd'

import axios from 'axios'
import configs from '../../api/configs'

const PAGE_SIZE = 50

export default class GiftOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            data: [], // 所有礼物
            filtered_data: [], // 显示礼物
            loading: true,
        }
    }

    componentDidMount () {
        // 获取所有礼物
        this.getAllGifts()

        const columnsOfPlayer = [
            {
                title: '报单时间(美东)',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '老板昵称',
                dataIndex: 'customer',
                key: 'customer',
            },
            {
                title: '礼物名称',
                dataIndex: 'gift_type',
                key: 'gift_type',
            },
            {
                title: '礼物数量',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: '收入金额($)',
                dataIndex: 'invoice',
                key: 'invoice',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
            },
        ]

        const columnsOfCustomer = [
            {
                title: '订单时间(美东)',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '陪玩昵称',
                dataIndex: 'player',
                key: 'player',
            },
            {
                title: '礼物名称',
                dataIndex: 'gift_type',
                key: 'gift_type',
            },
            {
                title: '礼物数量',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: '支付金额($)',
                dataIndex: 'invoice',
                key: 'invoice',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
            },
        ]
        this.setState({
            columns: (window.location.pathname === '/player/gift-orders' ? columnsOfPlayer : columnsOfCustomer)
        })
    }

    // 获取所有礼物
    getAllGifts = () => {
        var config_getOrders = configs.getOrders(PAGE_SIZE, 0)
        axios(config_getOrders)
            .then(
                response => {
                    this.handleOrders(response.data.data)
                    // 计算总共需请求的次数
                    var i
                    for (i = 1; i < Math.ceil(response.data.total / PAGE_SIZE); i++) {
                        config_getOrders = configs.getOrders(PAGE_SIZE, i)
                        axios(config_getOrders)
                            .then(
                                response => {
                                    this.handleOrders(response.data.data)
                                }
                            ).catch(
                                error => {
                                    console.log(error)
                                    message.error('获取页面信息失败，请刷新界面或联系客服!')
                                }
                            )
                    }
                }
            ).catch(
                error => {
                    console.log(error)
                    message.error('获取页面信息失败，请刷新界面或联系客服!')
                }
            )
    }

    handleOrders = (dataSource) => {
        var dataToAdd = []
        var i
        for (i = 0; i < dataSource.length; i++) {
            if (dataSource[i].order.game_type === 'Gift') {
                dataToAdd.push({
                    key: dataSource[i].key,
                    id: dataSource[i].order.id,
                    status: this.handleStatus(dataSource[i].order.status),
                    gift_type: dataSource[i].order.game_level,
                    amount: dataSource[i].order.hours,
                    created_at: dataSource[i].order.created_at.split('T')[0] + ' - ' + dataSource[i].order.created_at.split('T')[1].split('.')[0],
                    customer: dataSource[i].customers.length !== 0 ? dataSource[i].customers[0].name : '(无用户记录)',
                    player: dataSource[i].players.length !== 0 ? dataSource[i].players[0].name : '(无陪玩记录)',
                    invoice: this.handleInvoice(dataSource[i].invoice, dataSource[i].order.status)
                })
            }
        }
        var data = this.state.data.concat(dataToAdd)
        this.setState({
            loading: false,
            data,
            filtered_data: data
        })
    }

    handleStatus = (status) => {
        if (status === 0) {
            return <Badge status='processing' text='待审核' />
        }
        else if (status === 1) {
            return <Badge status='success' text='已完成' />
        }
        else if (status === 99) {
            return <Badge status='default' text='已取消' />
        }
    }

    // 获取income或者payment的数值的方法
    handleInvoice = (invoice, status) => {
        if (invoice.length !== 0) {
            if (status !== 99) {
                return Number(invoice[0].amount)
            }
            else {
                return 0
            }
        }
        else {
            return 0
        }
    }

    render () {
        return (
            <div style={{ letterSpacing: '1px', fontFamily: 'Arial' }}>
                <Table
                    columns={this.state.columns}
                    dataSource={this.state.filtered_data}
                    loading={this.state.loading}
                    title={() => "订单数: " + this.state.filtered_data.length}
                />
            </div>
        )
    }
}