import { Component } from 'react'
import { Divider, Layout, Collapse } from 'antd'

import './achievement.css'

const { Panel } = Collapse
const { Header, Footer, Content } = Layout

export default class Achievement extends Component {
  render() {
    return (
      <div className="achievement-body">
        <div>
          <h2 style={{ textAlign: 'center', fontSize: '2em' }}>
            CKP成就系统说明
          </h2>
          <Content>
            <div className="part1">
              <p
                style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 18 }}>
                基本说明
              </p>
              <div className="part1_body">
                <p>
                  -实习为<span className="number"> 0.6 </span>，转正方式有:
                  <br />
                  1. 成功接单报单一次无投诉;
                  <br />
                  2. 拥有任意游戏Rank TAG。
                  <br />
                  <span style={{ fontStyle: 'italic', color: 'green' }}>
                    *完成两种中任意一种，并仔细阅读并了解
                    ｜内部通知-公告｜𝐂𝐊𝐏规矩入职必读｜必读薪资规则，即可转正。
                    <br />
                    所有CKP陪玩扣单默认代表着您同意陪玩制度，游戏价格等所有的CKP规矩。
                  </span>
                </p>
                <p
                  style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: 18,
                  }}>
                  CKP 陪玩福利
                </p>
                <p>
                  -所有正式陪陪、独家陪陪初始分成为
                  <span className="number"> 0.75 </span>，挂名陪陪初始分成为
                  <span className="number"> 0.72 </span>，
                  游戏大神前缀的初始分成为<span className="number"> 0.75 </span>
                  ；
                  <br />
                  -陪陪生日当天收到的礼物冠名
                  <span className="number"> 0.8 </span>(必须通过频道登记)，超过
                  <span className="number"> 0.8 </span>的陪陪按自身比例分成;
                </p>
                (
                <span
                  style={{
                    textDecoration: 'underline',
                    fontFamily: 'Cursive',
                    fontWeight: 'bold',
                  }}>
                  注: 特殊分成的报单备注请清楚备注说明
                </span>
                )
              </div>
            </div>
            <Divider />
            <div className="part2">
              <p
                style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 18 }}>
                CKP成就达成系统(3.0)
              </p>
              <Collapse defaultActiveKey={['1']}>
                <Panel header="1-收入成就(最高分成+0.1)" key="1">
                  <Collapse>
                    <Panel header="独家陪玩" key="1-1">
                      <p>
                        累计收入满 500 &nbsp;&nbsp; 分成+0.01
                        <br />
                        累计收入满 1000 &nbsp;&nbsp; 分成+0.02
                        <br />
                        累计收入满 2000 &nbsp;&nbsp; 分成+0.03,
                        晋升为银牌（单价+1）
                        <br />
                        累计收入满 3000 &nbsp;&nbsp; 分成+0.04
                        <br />
                        累计收入满 5000 &nbsp;&nbsp; 分成+0.05
                        <br />
                        累计收入满 8888 &nbsp;&nbsp; 晋升为金牌陪玩单价+2，
                        获得金牌单独分组TAG
                      </p>
                    </Panel>
                    <Panel header="挂名陪玩" key="1-2">
                      <p>
                        累计收入满 2000 &nbsp;&nbsp; 分成改为0.75
                        <br />
                        累计收入满 5000 &nbsp;&nbsp; 晋升为银牌（单价+1）
                        <br />
                        累计收入满 8000 &nbsp;&nbsp; 分成改为0.76
                        <br />
                        累计收入满 16666 &nbsp;&nbsp; 分成改为0.77
                        晋升为金牌陪玩单价+2，获得金牌单独分组TAG
                      </p>
                    </Panel>
                  </Collapse>
                </Panel>
                <Panel header="2-自带老板福利" key="2">
                  <p>
                    -自带需提供邀请老板清晰可见的聊天记录，无记录的一概不算（需发送给啃啃，DC
                    id：啃啃#5206进行后台登记）
                    <br />
                    <p>
                      -自带老板分为两种奖励方式，可供选择:
                      <br />
                      1. 单子<span className="number"> 0.8 </span>分成，礼物
                      <span className="number"> 0.85 </span>
                      分成;
                      <br />
                      2. 老板1个月内的充值陪玩提成
                      <span className="number"> 5% </span>
                      <br />-
                      如果该老板已经在公会里，之前从来没有过充值记录，在你的推荐介绍下消费了，只可以选择第二种奖励方式。
                    </p>
                    <p>
                      -
                      奖励方式1，老板除代金券和充值返现外，其他VIP福利和权益都享有
                    </p>
                    <p>
                      - 奖励方式2，老板除充值返现以外所有的VIP福利和权益都有
                    </p>
                  </p>
                </Panel>
                <Panel header="3-推广福利" key="3">
                  <p>
                    -生成CKP的Discord永久私人邀请链接，该链接每使用一次获得
                    <span className="number">$0.2</span>
                    奖励，每月结算一次与薪资一同发放，生成的永久链接需发送给值班客服进行登记。
                  </p>
                </Panel>
                <Panel header="4-宣传福利" key="4">
                  <p>
                    -帮CKP公会宣传一次至少保持1天时间分成
                    <span className="number">+0.02</span>，该分成持续两周时间
                  </p>
                  <p>
                    -文案内容可添加CKP客服微信复制朋友圈或者自己写，必须包括CKP邀请链接（可用推广福利里生成的永久个人链接）
                  </p>
                  <p>
                    -形式有但不限于，微信朋友圈，QQ游戏群组，微信游戏群组，相关网站，小红书，抖音（每个月不可重复使用同一种形式）
                  </p>
                </Panel>
                <Panel header="5-介绍福利" key="5">
                  <p>
                    -介绍一名独家陪玩入职CKP，将享有该陪玩入职第一个月薪资的
                    <span className="number">5%</span>现金奖励
                  </p>
                  <p>
                    -介绍一名大神TAG陪玩入职CKP，将享有该陪玩入职第一个月薪资的
                    <span className="number">5%</span>
                    现金奖励两者可叠加，如即是独家，又是大神TAG，将享有
                    <span className="number">10%</span>
                  </p>
                </Panel>
                <Panel header="6-每周每月榜单福利" key="6">
                  <Collapse>
                    <Panel header="每周榜单" key="2-1">
                      <p>分男女榜，榜一奖励忘忧草，榜二永生花，榜三花环</p>
                      <p>如独家在榜单，额外奖励果子</p>
                    </Panel>
                    <Panel header="每月榜单" key="2-2">
                      <p>
                        不分男女榜，榜一下月增加
                        <span className="number">0.05</span>分成，榜二下月增加
                        <span className="number">0.03</span>分成，榜三下月增加
                        <span className="number">0.01</span>分成
                      </p>
                      <p>
                        如独家在榜单，榜一额外奖励甜甜花，榜二额外奖励忘忧草，榜三额外奖励永生花。
                      </p>
                    </Panel>
                  </Collapse>
                </Panel>
              </Collapse>
              <Divider />
              <p
                style={{
                  textDecoration: 'underline',
                  fontFamily: 'Cursive',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}>
                注: 所有的成就福利或者活动分成增加的奖励，独家上限为
                <span className="number">0.85</span>，挂名上限为
                <span className="number">0.8</span>。
                <br />
                EX：比如独家陪玩A，现有分成是
                <span className="number">0.82</span>
                ，拿到了月榜第一，奖励下一个月
                <span className="number">0.05</span>的分成增加，但最终的比例会是
                <span className="number">0.85</span>。
              </p>
            </div>
          </Content>
          <Footer
            style={{
              backgroundColor: '#f0f0f0',
              fontSize: 16,
              textAlign: 'center',
            }}>
            有任何的疑问请联系啃啃，DC id：啃啃#5206
            <br />
            最终解释权归CKP Club所有
          </Footer>
        </div>
      </div>
    )
  }
}
