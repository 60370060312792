import { Component } from "react"
import moment from 'moment'
import { SearchOutlined } from '@ant-design/icons'
import { Table, message, Badge, Button, Space, Divider, DatePicker, Drawer } from 'antd'

import axios from 'axios'
import configs from '../../api/configs'

import '../css/button.css'

const { RangePicker } = DatePicker
const dateFormat = 'YYYYMMDD'
const PAGE_SIZE = 50
const DASHEN_TAG_PRICE = 2

export default class GameOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            data: [],
            filtered_data: [],
            loading: true,
            startDate: 0,
            endDate: 0,
            detail_visib: false,
            detail_record: {},
        }
    }

    componentDidMount () {
        //获取所有订单
        this.getAllOrders()

        const columnsOfPlayer = [
            {
                title: '报单时间(美东)',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '老板昵称',
                dataIndex: 'customer',
                key: 'customer',
            },
            {
                title: '游戏类型',
                dataIndex: 'game_type',
                key: 'game_type',
            },
            {
                title: '收入金额($)',
                dataIndex: 'invoice',
                key: 'invoice',
            },
            {
                title: '订单详情',
                dataIndex: 'details',
                key: 'details',
                render: (text, record, index) => {
                    return (
                        <Space size='small'>
                            <Button className="action-btn" onClick={() => { window.location.href = '/player/game-order/' + record.key }}>查看</Button>
                        </Space>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
            }
        ]

        const columnsOfCustomer = [
            {
                title: '订单时间(美东)',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '陪玩昵称',
                dataIndex: 'player',
                key: 'player',
            },
            {
                title: '游戏类型',
                dataIndex: 'game_type',
                key: 'game_type',
            },
            {
                title: '支付金额($)',
                dataIndex: 'invoice',
                key: 'invoice',
            },
            {
                title: '订单详情',
                dataIndex: 'details',
                key: 'details',
                render: (text, record, index) => {
                    return (
                        <Space size='small'>
                            <Button className="action-btn" onClick={() => { this.setState({ detail_visib: true, detail_record: record }) }}>查看</Button>
                        </Space>
                    )
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
            }
        ]

        this.setState({
            columns: (window.location.pathname === '/player/game-orders' ? columnsOfPlayer : columnsOfCustomer)
        })
    }

    getAllOrders = () => {
        var config_getOrders = configs.getOrders(PAGE_SIZE, 0)
        axios(config_getOrders)
            .then(
                response => {
                    this.handleOrders(response.data.data)
                    // 计算总共需请求的次数
                    var i
                    for (i = 1; i < Math.ceil(response.data.total / PAGE_SIZE); i++) {
                        config_getOrders = configs.getOrders(PAGE_SIZE, i)
                        axios(config_getOrders)
                            .then(
                                response => {
                                    this.handleOrders(response.data.data)
                                }
                            ).catch(
                                error => {
                                    console.log(error)
                                    message.error('获取页面信息失败，请刷新界面或联系客服!')
                                }
                            )
                    }
                }
            ).catch(
                error => {
                    console.log(error)
                    message.error('获取页面信息失败，请刷新界面或联系客服!')
                }
            )
    }

    handleOrders = (dataSource) => {
        var dataToAdd = []
        var i
        for (i = 0; i < dataSource.length; i++) {
            if (dataSource[i].order.game_type !== 'Gift') {
                dataToAdd.push({
                    key: dataSource[i].key,
                    id: dataSource[i].order.id,
                    status: this.handleStatus(dataSource[i].order.status),
                    game_type: dataSource[i].order.game_type,
                    game_level: dataSource[i].order.game_level,
                    hours: dataSource[i].order.hours,
                    overtime: dataSource[i].order.overtime,
                    invoice: this.handleInvoice(dataSource[i].invoice, dataSource[i].order.status),
                    created_at: dataSource[i].order.created_at.split('T')[0] + ' - ' + dataSource[i].order.created_at.split('T')[1].split('.')[0],
                    customer: dataSource[i].customers.length !== 0 ? dataSource[i].customers[0].name : '(无用户记录)',
                    player: dataSource[i].players.length !== 0 ? dataSource[i].players[0].name : '(无陪玩记录)',
                    player_type: dataSource[i].order.meta !== null ? JSON.parse(dataSource[i].order.meta).player_type[0].split(':$')[0] : '',
                    player_num: dataSource[i].order.meta !== null ? JSON.parse(dataSource[i].order.meta).player_num[0].split(':$')[0] : '',
                    dashen_tag: dataSource[i].order.meta !== null ? (JSON.parse(dataSource[i].order.meta).player_level === true ? '是' : '否') : '',
                    price: dataSource[i].order.meta !== null ? (JSON.parse(dataSource[i].order.meta).player_level === true ? (JSON.parse(dataSource[i].order.meta).price + DASHEN_TAG_PRICE) : JSON.parse(dataSource[i].order.meta).price) : 0,
                    //discount: dataSource[i].bill.fomulas[0].formula.split('=')[0].split(')*')[2]
                })
            }
        }
        var data = this.state.data.concat(dataToAdd)
        this.setState({
            loading: false,
            data,
            filtered_data: data
        })
    }

    handleStatus = (status) => {
        if (status === 0) {
            return <Badge status='processing' text='待审核' />
        }
        else if (status === 1) {
            return <Badge status='success' text='已完成' />
        }
        else if (status === 99) {
            return <Badge status='default' text='已取消' />
        }
    }

    // 获取income或者payment的数值的方法
    handleInvoice = (invoice, status) => {
        if (invoice.length !== 0) {
            if (status !== 99) {
                return Number(invoice[0].amount)
            }
            else {
                return 0
            }
        }
        else {
            return 0
        }
    }

    disabledDate = (current) => {
        return current > moment().endOf('day')
    }

    setDate = (value, mode) => {
        if (value === null) {
            message.info('请选择日期!')
        }
        else {
            this.setState({
                startDate: value[0].format(dateFormat),
                endDate: value[1].format(dateFormat)
            })
        }
    }

    filter = () => {
        if (this.state.startDate === 0) {
            message.info('请选择日期!')
        }
        else {
            var orders = []
            this.state.data.forEach(
                obj => {
                    if (this.compareDate(obj)) {
                        orders.push(obj)
                    }
                }
            )
            this.setState({
                filtered_data: orders
            })
        }
    }

    // 判断一个order是否在时间区间内，如果是返回true，否则返回false
    compareDate = (order) => {
        var date = parseInt(moment(order.created_at.split(' - ')[0]).format(dateFormat))
        return date >= this.state.startDate && date <= this.state.endDate
    }

    render () {
        return (
            <div style={{ letterSpacing: '1px', fontFamily: 'Arial' }}>
                <Space direction='horizontal' size={12}>
                    <RangePicker disabledDate={this.disabledDate} onChange={this.setDate} />
                    <Space direction='horizontal' size={12}>
                        <Button className="action-btn" icon={<SearchOutlined />} onClick={this.filter}>
                            搜索
                        </Button>
                        <Button className="action-btn" onClick={() => { this.setState({ filtered_data: this.state.data }) }}>
                            重置
                        </Button>
                    </Space>
                </Space>
                <Divider />
                <Table
                    columns={this.state.columns}
                    dataSource={this.state.filtered_data}
                    loading={this.state.loading}
                    title={() => "订单数: " + this.state.filtered_data.length}
                />
                <Drawer
                    title="订单详情"
                    placement="right"
                    onClose={() => { this.setState({ detail_visib: false }) }}
                    visible={this.state.detail_visib}
                >
                    <div style={{ fontFamily: 'Arial', fontSize: 16, fontWeight: '500' }}>
                        <h3>游戏订单 - ID#{this.state.detail_record.id}</h3>
                        <p>状态: {this.state.detail_record.status}</p>
                        <p>时间: {this.state.detail_record.created_at}</p>
                        <p>游戏类型: {this.state.detail_record.game_type}</p>
                        <p>游戏等级: {this.state.detail_record.game_level}</p>
                        <p>陪玩类型: {this.state.detail_record.player_type}</p>
                        <p>陪玩人数: {this.state.detail_record.player_num}</p>
                        <p>是否有大神TAG: {this.state.detail_record.dashen_tag}</p>
                        <p>时长/局数: {this.state.detail_record.hours}</p>
                        <p>备注: {this.state.detail_record.comments}</p>
                        <Divider />
                        <div style={{ textAlign: 'right' }}>
                            <p>最终单价: ${this.state.detail_record.price}</p>
                            <p>小计: ${this.state.detail_record.price !== 0 ? this.state.detail_record.price * this.state.detail_record.hours + (this.state.detail_record.price + 2) * this.state.detail_record.overtime : 0}</p>
                            {/*<p>当前折扣率: {this.state.detail_record.discount * 100}%</p>*/}
                            <Divider style={{ width: '50%' }} />
                            <h3>总计: <span style={{ color: '#f5222d' }}>${this.state.detail_record.invoice}</span></h3>
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }
}