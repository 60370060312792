import { Component } from "react"
import { Descriptions, message, Card, Divider, List, Typography } from 'antd'

import '../css/dashboard.css'

import axios from 'axios'
import configs from '../../api/configs'

import Relationship from './Relationship'

const IDENTITY = 'customers'
// 最近通知或更新的文本信息
const data = [
    <Typography.Text>
        <Typography.Text mark>[2022-08-26]</Typography.Text> 新增订单详情界面
    </Typography.Text>,
    <Typography.Text>
        <Typography.Text mark>[2022-08-18]</Typography.Text> 更新账单界面
    </Typography.Text>,
    <Typography.Text>
        <Typography.Text mark>[2022-08-06]</Typography.Text> 修改了首页布局
    </Typography.Text>,
]

export default class User_Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            created_at: "",
            balance: 0,
            consumption: 0,
            discount: 0,
            loading: true,
        }
    }

    componentDidMount () {
        // 获取基本信息
        this.getInfo()
    }

    // 获取基本信息
    getInfo = () => {
        var config_getInfo = configs.reqInfo(IDENTITY)
        axios(config_getInfo)
            .then(
                response => {
                    this.setState({
                        loading: false,
                        name: response.data.customer.name,
                        email: response.data.customer.email,
                        phone: response.data.customer.phone,
                        created_at: response.data.customer.created_at.split("T")[0] + " " + response.data.customer.created_at.split("T")[1].split(".")[0],
                        balance: Number(response.data.balance.amount),
                        consumption: Number(response.data.balance.consumption),
                        discount: Number(response.data.balance.discount)
                    })
                }
            ).catch(
                error => {
                    console.log(error)
                    message.error('页面加载失败，请刷新界面或联系客服: ' + error)
                }
            )
    }

    render () {
        const cardHeadStyle = {
            background: '#f5f5f5',
            fontWeight: '700',
        }
        const cardBodyStyle = {
            color: '#f5222d',
            fontWeight: '600',
            fontSize: '1.3em',
            fontFamily: 'Arial',
        }
        const NoticeBox = {
            width: '50%',
            height: '200px',
            float: 'right',
            paddingLeft: '24px',
            overflow: 'scroll'
        }

        return (
            <div className='dashboard-page'>
                <div>
                    <Descriptions bordered title="个人信息">
                        <Descriptions.Item key={1} label="昵称">
                            {this.state.name}
                        </Descriptions.Item>
                        <Descriptions.Item key={2} label="Email">
                            {this.state.email === null ? '未提供' : this.state.email}
                        </Descriptions.Item>
                        <Descriptions.Item key={3} label="手机号">
                            {this.state.phone === null ? '未提供' : this.state.phone}
                        </Descriptions.Item>
                        <Descriptions.Item key={4} label="入职时间">
                            {this.state.created_at}
                        </Descriptions.Item>
                    </Descriptions>
                </div>
                <Divider />
                <div style={{ display: 'block' }}>
                    <div className="card-wrapper" style={{ width: '50%', float: 'left' }}>
                        <div style={{ width: '50%' }}>
                            <Card
                                title="余额"
                                headStyle={cardHeadStyle}
                                bodyStyle={cardBodyStyle}
                                loading={this.state.loading}
                            >
                                ${this.state.balance}
                            </Card>
                        </div>
                        <div style={{ width: '50%', marginLeft: 24 }}>
                            <Card
                                title="VIP折扣率"
                                headStyle={cardHeadStyle}
                                bodyStyle={cardBodyStyle}
                                loading={this.state.loading}
                            >
                                {this.state.discount * 100}%
                            </Card>
                        </div>
                    </div>
                </div>
                <Divider />
                <div>
                    <h2>心动值</h2>
                    <Relationship />
                </div>
            </div>
        )
    }
}